// React
import { useCallback, useEffect, useState } from "react";
// MainStem
import { Loader, useOnFirstLoad } from "@mainstem/react-mainstem";
// API's
import { APIServerlessFrictionFreeSearch } from "api/serverless/v1/friction-free/search";
import { APIServerlessFrictionFreeSearchResponse } from "api/serverless/v1/friction-free/search/response";
// Local - Assets - Images
import imgStamp from "./assets/img/stamp.png";
import imgULine from "./assets/img/uline.png";
// Local - Styled Components
import {
  SCFrictionFreeSearchInput,
  SCFrictionFreeSearchInputContainer,
  SCFrictionFreeSearchInputLoader,
  SCFrictionFreeSearchResult,
  SCFrictionFreeSearchResultProductImage,
  SCFrictionFreeSearchResultProductImageContainer,
  SCFrictionFreeSearchResultProductName,
  SCFrictionFreeSearchResultProductSource,
  SCFrictionFreeSearchResultProductSourceContainer,
  SCFrictionFreeSearchResults,
  SCFrictionFreeSearchResultsCount,
} from "./styles";

/**
 * FrictionFreeSearch : Renders a search bar that allows users to type in a search query and get results.
 * @returns
 */
const FrictionFreeSearch: React.FC = () => {
  const [searchResults, setSearchResults] =
    useState<APIServerlessFrictionFreeSearchResponse>();

  const [query, setQuery] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  /**
   * Loads search results from the API into state.
   */
  const loadSearchResults = useCallback(() => {
    setLoading(true);
    const apiRequest = {
      query,
    };
    APIServerlessFrictionFreeSearch(apiRequest).then((apiResponse) => {
      setSearchResults(apiResponse);
      setLoading(false);
    });
  }, [query]);

  useEffect(() => {
    if (query) {
      loadSearchResults();
    } else {
      setSearchResults(undefined);
    }
  }, [query, loadSearchResults]);

  /**
   * Fires when the component first renders.
   */
  useOnFirstLoad(() => {
    // TODO:
  });

  return (
    <>
      <SCFrictionFreeSearchInputContainer>
        <SCFrictionFreeSearchInputLoader>
          {loading && <Loader color="light" containerHeight={20} size="sm" />}
        </SCFrictionFreeSearchInputLoader>
        <SCFrictionFreeSearchInput
          onChange={({ target }) => {
            setQuery(target.value);
          }}
          placeholder="Search products, services and vendors."
          type="text"
        />
      </SCFrictionFreeSearchInputContainer>

      {searchResults?.results.length && (
        <>
          <SCFrictionFreeSearchResultsCount>
            {searchResults?.results.length} Results Found
          </SCFrictionFreeSearchResultsCount>
        </>
      )}
      {searchResults?.results.length && (
        <>
          <SCFrictionFreeSearchResults>
            {searchResults?.results.map((searchResult) => {
              return (
                <SCFrictionFreeSearchResult
                  href={
                    searchResult.product.source === "ULine"
                      ? searchResult.product.url
                      : `/products/details/${searchResult.product.id}`
                  }
                  key={searchResult.id}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <SCFrictionFreeSearchResultProductSourceContainer>
                    <SCFrictionFreeSearchResultProductSource
                      src={
                        searchResult.product.source === "ULine"
                          ? imgULine
                          : imgStamp
                      }
                    />
                  </SCFrictionFreeSearchResultProductSourceContainer>
                  <SCFrictionFreeSearchResultProductImageContainer>
                    <SCFrictionFreeSearchResultProductImage
                      src={searchResult.product.image}
                    />
                  </SCFrictionFreeSearchResultProductImageContainer>
                  <SCFrictionFreeSearchResultProductName
                    dangerouslySetInnerHTML={{
                      __html: searchResult.rank.value,
                    }}
                  ></SCFrictionFreeSearchResultProductName>
                </SCFrictionFreeSearchResult>
              );
            })}
          </SCFrictionFreeSearchResults>
        </>
      )}
    </>
  );
};

export { FrictionFreeSearch };
