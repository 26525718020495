// Mantine
import { Divider, LoadingOverlay } from "@mantine/core";
// MainStem
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardHeaderActions,
  CardHeaderTitle,
  Grid,
  GridItem,
  PageTitle,
  theme,
} from "@mainstem/react-mainstem";
// Auth0
import { withAuthenticationRequired } from "@auth0/auth0-react";
// Global - Components
import { ExampleData } from "components";
// Local - Assets - Images
import imgScreenshot1 from "./assets/img/screenshot-1.jpg";
import imgChrome from "./assets/img/chrome.png";
// import imgScreenshot3 from "./assets/img/screenshot-3.jpg";
// import imgScreenshot4 from "./assets/img/screenshot-4.jpg";

const PageSideKick: React.FC = () => {
  return (
    <>
      <PageTitle
        image={theme.logos.mainstem.color}
        subtitle="Order from anywhere and let MainStem handle the check-out, payment and shipment tracking."
        title="[AI] - SideKick™"
      />
      <Divider my="md" />
      <Grid>
        <GridItem md={8}>
          <ExampleData />
        </GridItem>
        <GridItem md={4}>
          <img
            alt="MainStem SideKick Screenshot"
            src={imgScreenshot1}
            style={{ marginBottom: 15, width: "100%" }}
          />
          <Card>
            <CardHeader
              actions={
                <CardHeaderActions>
                  <a
                    href="https://chrome.google.com/webstore/detail/fpjbghhfkgcjdfecmgjneobfcfijhjfa/preview?hl=en&authuser=0"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Button>View on Chrome Web Store</Button>
                  </a>
                </CardHeaderActions>
              }
              title={<CardHeaderTitle>Chrome Web Store</CardHeaderTitle>}
            />
            <CardBody>
              <img
                alt="MainStem SideKick Screenshot"
                src={imgChrome}
                style={{
                  border: "1px solid #e8e8e8",
                  width: "100%",
                }}
              />
            </CardBody>
          </Card>
          <br />
          <Card>
            <CardHeader
              actions={
                <CardHeaderActions>
                  <Button>View on Edge Web Store</Button>
                </CardHeaderActions>
              }
              title={<CardHeaderTitle>Edge Web Store</CardHeaderTitle>}
            />
            <CardBody>
              <p>Lorem ipsum</p>
            </CardBody>
          </Card>
        </GridItem>
      </Grid>
    </>
  );
};

export default withAuthenticationRequired(PageSideKick, {
  onRedirecting: () => <LoadingOverlay visible />,
});
