import { PageTitle, theme } from "@mainstem/react-mainstem";
import { Divider } from "@mantine/core";
import { ExampleData } from "components";

const PageIntegrationSettings: React.FC = () => {
  return (
    <>
      <PageTitle
        image={theme.logos.mainstem.color}
        title="Integration Settings"
      />      
      <Divider my="md" />
      <ExampleData />
    </>
  );
};

export default PageIntegrationSettings;
