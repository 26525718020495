import { PageTitle, theme } from "@mainstem/react-mainstem";
import { Divider } from "@mantine/core";
import { ExampleData } from "components";
import { useParams } from "react-router-dom";

const PagePurchaseOrderApprove: React.FC = () => {
  const { id } = useParams();
  return (
    <>
      <PageTitle
        image={theme.logos.mainstem.color}
        title={`Purchase Order : Details #${id}`}
      />
      <Divider my="md" />
      <ExampleData />
    </>
  );
};

export default PagePurchaseOrderApprove;
