// Mantine
import { Card, Space, Title } from "@mantine/core";
import { Link } from "react-router-dom";
// Local - Components
import { FeedCard, QuoteCard } from "./components";
import imgSalesEvent from "assets/img/summer-sales-event.jpg";

const MSFeed: React.FC = () => {
  return (
    <>
      <Card
        shadow={"lg"}
        style={{
          backgroundImage:
            "linear-gradient(238deg, rgb(26 36 37) 23%, rgb(6 35 67))",
          zIndex: 1,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          position: "fixed",
          width: 267,
          padding: '5px 0px'
        }}
      >
        <Title
          align="center"
          style={{
            fontSize: 20,
            fontWeight: "500",
            color: "#FFF",
          }}
        >
          MainStem SideKick™
        </Title>
      </Card>
      <Space h={30} />
      <FeedCard
        buttonText="View Details..."
        description="Save big on all your cannabis supplies! Shop the MainStem summer sales event now! #cannabis #summersale #supplies https://hubs.la/Q01hByDV0"
        title="Save On Supplies!"
        onClick={() => {
          // TODO: Implement
        }}
        image={imgSalesEvent}
      />
      <Space h="md" />
      <QuoteCard
        postedAt="10 minutes ago"
        body={
          <>
            I need approval on{" "}
            <Link to="/purchase-orders/approve/14356/">Order #14536.</Link>
          </>
        }
        author={{
          name: "Jacob Warnhalter",
          image:
            "https://images.unsplash.com/photo-1624298357597-fd92dfbec01d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=250&q=80",
        }}
      />
      <Space h="md" />
      <FeedCard
        buttonText="Read More..."
        description="Supply chain is a catchall phrase to represent the movement of goods or parts from suppliers to the next stage of production. The product is adjusted, and then moves on to the next stage, which could be another producer or a retailer."
        title="Supply Chain Isssues Continue"
        onClick={() => {
          // TODO: Implement
        }}
        image="https://images.unsplash.com/photo-1634638026221-4c1c4cf9f881?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1207&q=80"
      />
      <Space h="md" />
      <FeedCard
        buttonText="Learn More..."
        description="Ever wanted to know how MainStem audits your growing supplies?  "
        title="Supply Audit : Growing Supplies"
        onClick={() => {
          // TODO: Implement
        }}
        image="https://images.unsplash.com/photo-1617488983195-93997cb57cdd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
      />
    </>
  );
};

export { MSFeed };
