// Mantine
import { PageTitle, theme } from "@mainstem/react-mainstem";
// Global - Components
import { ExampleReport } from "components";

const PageAnalyticsSupplyAudits: React.FC = () => {
  return (
    <>
      <PageTitle
        image={theme.logos.mainstem.color}
        title="Analytics - Supply Audits"
        subtitle="Analytics by Microsoft PowerBI Embedded"
      />
      <br />
      <ExampleReport />
    </>
  );
};

export default PageAnalyticsSupplyAudits;
