import { Chip, Group } from "@mantine/core";
import { ITags } from "./types";

const Tags: React.FC<ITags> = ({ onAddFilter, onRemoveFilter }: ITags) => {
  const tags = [
    "E-Commerce",
    "ERP",
    "Data",
    "API",
    "Distributor",
    "Accounting",
    "Financial Services",
    "Communication",
  ];
  return (
    <Group>
      {tags.map((tag) => {
        return (
          <Chip
            key={tag}
            onChange={(checked) => {
              console.log("changed");
              if (checked) {
                onAddFilter(tag);
              } else {
                onRemoveFilter(tag);
              }
            }}
          >
            {tag}
          </Chip>
        );
      })}
    </Group>
  );
};

export { Tags };
