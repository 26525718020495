import { PageTitle, theme } from "@mainstem/react-mainstem";
import { Button } from "@mantine/core";
import { Tree, TreeNode } from "react-organizational-chart";

const PageDepartmentsIndex: React.FC = () => {
  const StyledNode: React.FC<any> = ({ children }: any) => {
    return (
      <>
        <Button variant="outline">{children}</Button>
      </>
    );
  };

  return (
    <>
      <PageTitle image={theme.logos.mainstem.color} title="Departments" />
      <br />
      <Tree label={<StyledNode>A-Z Company - WA</StyledNode>}>
        <TreeNode label={<StyledNode>Accounting</StyledNode>}>
          <TreeNode label={<StyledNode>A/P</StyledNode>} />
        </TreeNode>
        <TreeNode label={<StyledNode>Purchasing</StyledNode>}>
          <TreeNode label={<StyledNode>Regional Purchaser</StyledNode>}>
            <TreeNode
              label={<StyledNode>Location Purchasing Director</StyledNode>}
            />
            <TreeNode label={<StyledNode>Great Grand Child 2</StyledNode>} />
          </TreeNode>
        </TreeNode>
        <TreeNode label={<StyledNode>Growing</StyledNode>}>
          <TreeNode label={<StyledNode>Location Director</StyledNode>} />
          <TreeNode label={<StyledNode>Grow Manager</StyledNode>} />
        </TreeNode>
      </Tree>
    </>
  );
};

export default PageDepartmentsIndex;
