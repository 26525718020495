const messagesInEnglish = {
  home: "Home",
  integrations: "Integrations",
  support: "Support",
  blog: "Blog",
  APITechnology: "API & Technology",
  financialServices: "Financial Services",
};

export { messagesInEnglish };
