// React
import { useEffect, useState } from "react";
// Mantine
import {
  Avatar,
  Card,
  Chip,
  Group,
  LoadingOverlay,
  Stack,
  Tabs,
  Text,
  Title,
  createStyles,
  useMantineTheme,
  SimpleGrid,
  Skeleton,
  Grid,
} from "@mantine/core";
import {
  setNavigationProgress,
  startNavigationProgress,
  stopNavigationProgress,
} from "@mantine/nprogress";
// MainStem API
import { Api } from "@mainstem/mainstem-api";
// Icons
import { IconHome2, IconReportMoney, IconUsers } from "@tabler/icons";

import imgHeader from "assets/img/examples/background-ethos.png";
import imgLogo from "assets/img/examples/logo-ethos.png";

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    border: `2px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[2]
    }`,
    borderRadius: 15,
  },

  avatar: {
    backgroundColor: theme.white,
    objectFit: "contain",
    border: `2px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[2]
    }`,
  },
}));

const PageOrganization: React.FC = () => {
  const theme = useMantineTheme();
  const id = "2";
  const { classes } = useStyles();
  const [doneLoading, setDoneLoading] = useState<boolean>(false);
  const [organization, setOrganization] = useState<any>();

  useEffect(() => {
    if (id) {
      setDoneLoading(false);
      startNavigationProgress();
      setNavigationProgress(25);
      const mainstemApi = new Api();
      const apiRequest = {
        id: parseInt(id),
      };
      setOrganization({
        name: "Ethos Cannabis",
        statement: "Pain is part of being human. Together let's find relief.",
        type: "Multi-State Cannabis Operator",
        address: {
          line1: "1210 S Montana Ave",
          line2: "",
          line3: "",
          city: "Seattle",
          state: "WA",
          postalCode: "98134",
          country: "US",
        },
      });
      mainstemApi.organization
        .organizationDetails(apiRequest)
        .then((apiResponse) => {
          // setOrganization(apiResponse.data);
        })
        .catch(() => {
          // window.alert("API error!");
        })
        .finally(() => {
          setNavigationProgress(100);
          stopNavigationProgress();
          setDoneLoading(true);
        });
    }
  }, [id]);

  const PRIMARY_COL_HEIGHT = 300;
  const SECONDARY_COL_HEIGHT = PRIMARY_COL_HEIGHT / 2 - theme.spacing.md / 2;

  return (
    <>
      {!doneLoading ? (
        <LoadingOverlay visible />
      ) : (
        <>
          {organization && (
            <>
              <Card className={classes.card}>
                <Card.Section
                  style={{
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundImage: `url('${imgHeader}')`,
                    height: 200,
                  }}
                />
                <Group>
                  <Avatar
                    className={classes.avatar}
                    src={imgLogo}
                    size={120}
                    radius={80}
                    mt={-30}
                  />
                  <Stack align={"start"} justify="center" spacing={0}>
                    <Title order={2}>{organization.name}</Title>
                    <Text>{organization.statement}</Text>
                    <Text color="dimmed" size="sm">
                      {organization.address.city}, {organization.address.state}{" "}
                      • {organization.type}
                    </Text>
                  </Stack>
                </Group>
              </Card>
              <Group align={"center"} position="center" my="md">
                <Chip.Group defaultValue={"Home"}>
                  <Chip size="md" value="Home">
                    Home
                  </Chip>
                  <Chip size="md" value="about">
                    About
                  </Chip>
                  <Chip size="md" value="Financials">
                    Financials
                  </Chip>
                  <Chip size="md" value="People">
                    People
                  </Chip>
                  <Chip size="md" value="Technology">
                    Technology
                  </Chip>
                  <Chip size="md" value="Signals">
                    Signals &amp; News
                  </Chip>
                  <Chip size="md" value="Analytics">
                    Analytics
                  </Chip>
                </Chip.Group>
              </Group>
              <Tabs
                style={{
                  backgroundColor: theme.white,
                  border: "1px solid #dee2e6",
                  borderLeft: "0px none",
                  borderRadius: 5,
                }}
                defaultValue="summary"
                orientation="vertical"
                radius="md"
                variant="outline"
              >
                <Tabs.List
                  grow
                  position="center"
                  style={{ backgroundColor: theme.white }}
                >
                  <Tabs.Tab
                    value="summary"
                    icon={<IconHome2 size={14} />}
                    style={{ backgroundColor: theme.white }}
                  >
                    Summary
                  </Tabs.Tab>
                  <Tabs.Tab
                    value="financials"
                    icon={<IconReportMoney size={14} />}
                    style={{ backgroundColor: theme.white }}
                  >
                    Financials
                  </Tabs.Tab>
                  <Tabs.Tab
                    value="people"
                    icon={<IconUsers size={14} />}
                    style={{ backgroundColor: theme.white }}
                  >
                    People
                  </Tabs.Tab>
                  <Tabs.Tab
                    value="technology"
                    icon={<IconUsers size={14} />}
                    style={{ backgroundColor: theme.white }}
                  >
                    Technology
                  </Tabs.Tab>
                  <Tabs.Tab
                    value="signals"
                    icon={<IconUsers size={14} />}
                    style={{ backgroundColor: theme.white }}
                  >
                    Signals &amp; News
                  </Tabs.Tab>
                  <Tabs.Tab
                    value="analytics"
                    icon={<IconUsers size={14} />}
                    style={{ backgroundColor: theme.white }}
                  >
                    Analytics
                  </Tabs.Tab>
                </Tabs.List>
                <Card style={{ width: "100%" }}>
                  <Tabs.Panel value="summary" pt="xs">
                    <SimpleGrid
                      cols={2}
                      spacing="md"
                      breakpoints={[{ maxWidth: "sm", cols: 1 }]}
                    >
                      <Skeleton
                        height={PRIMARY_COL_HEIGHT}
                        radius="md"
                        animate={false}
                      />
                      <Grid gutter="md">
                        <Grid.Col>
                          <Skeleton
                            height={SECONDARY_COL_HEIGHT}
                            radius="md"
                            animate={false}
                          />
                        </Grid.Col>
                        <Grid.Col span={6}>
                          <Skeleton
                            height={SECONDARY_COL_HEIGHT}
                            radius="md"
                            animate={false}
                          />
                        </Grid.Col>
                        <Grid.Col span={6}>
                          <Skeleton
                            height={SECONDARY_COL_HEIGHT}
                            radius="md"
                            animate={false}
                          />
                        </Grid.Col>
                      </Grid>
                    </SimpleGrid>
                  </Tabs.Panel>
                  <Tabs.Panel value="financials" pt="xs">
                    Financials tab content
                  </Tabs.Panel>
                  <Tabs.Panel value="people" pt="xs">
                    People tab content
                  </Tabs.Panel>
                  <Tabs.Panel value="technology" pt="xs">
                    Technology tab content
                  </Tabs.Panel>
                  <Tabs.Panel value="signals" pt="xs">
                    Signals &amp; News tab content
                  </Tabs.Panel>
                  <Tabs.Panel value="analytics" pt="xs">
                    Analyitcs tab content
                  </Tabs.Panel>
                </Card>
              </Tabs>
              {/* <Card
                style={{
                  backgroundColor: theme.colors.blue[0],
                  minHeight: 120,
                }}
              >
                <Group>
                  <Avatar
                    radius="md"
                    size="xl"
                    src="https://mainstem.blob.core.windows.net/files/51862e7b-5cea-4530-9a8a-d33cce333509.png"
                    style={{ backgroundColor: theme.white }}
                  />
                  <Title order={1}>{organization.name}</Title>
                </Group>
              </Card>
        
              <Card shadow={"md"}>Find More Contacts</Card> */}
            </>
          )}
        </>
      )}
    </>
  );
};
export default PageOrganization;
