import {
  UnstyledButton,
  UnstyledButtonProps,
  Group,
  Avatar,
  Text,
  createStyles,
  Badge,
} from "@mantine/core";
import { IconChevronRight } from "@tabler/icons";
import { useNavigate } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  user: {
    display: "block",
    width: "100%",
    padding: theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
    border: "1px solid #dee2e6",
    borderRadius: 5,
    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[8]
          : theme.colors.gray[0],
    },
  },
}));

interface UserButtonProps extends UnstyledButtonProps {
  image: string;
  name: string;
  email: string;
  id: string;
  icon?: React.ReactNode;
}

export function CustomerCard({
  image,
  name,
  email,
  id,
  icon,
  ...others
}: UserButtonProps) {
  const { classes } = useStyles();
  const navigate = useNavigate();
  return (
    <UnstyledButton
      className={classes.user}
      {...others}
      onClick={() => {
        navigate(`/customers/details/${id}`);
      }}
    >
      <Group>
        <Avatar src={image} radius="xl" />

        <div style={{ flex: 1 }}>
          <Text size="sm" weight={500}>
            {name}
          </Text>

          <Text color="dimmed" size="xs">
            {email}
          </Text>
        </div>

        {icon || <IconChevronRight size={14} stroke={1.5} />}
      </Group>
      <Badge color="green" variant="light" my={"sm"}>
        Buys $40K/mo in packaging
      </Badge>
    </UnstyledButton>
  );
}
