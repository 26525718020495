import { LoadingOverlay } from "@mantine/core";

const PageAuthAuthorize: React.FC = () => {
  return (
    <>
      <LoadingOverlay visible />
    </>
  );
};
export default PageAuthAuthorize;
