import axios from 'axios'
// Request
import { APIServerlessFrictionFreeSearchRequest } from "./request";
// Response
import { APIServerlessFrictionFreeSearchResponse } from "./response";

const HTTPServerless = axios.create({
  baseURL: "https://serverless-api.mainstem.io/api/v1/",
  headers: {
    "Ocp-Apim-Subscription-Key": "faf3dced054c4df48e7dee2e39c4c068",
  },
});

export async function APIServerlessFrictionFreeSearch(
  apiRequest: APIServerlessFrictionFreeSearchRequest
): Promise<APIServerlessFrictionFreeSearchResponse> {
  let apiResponse = {} as APIServerlessFrictionFreeSearchResponse;

  await HTTPServerless.post("v1/friction-free/search", apiRequest).then(
    (res: any) => {
      if (res.status === 200 && res.data) {
        apiResponse = res.data;
      }
    }
  );

  return apiResponse;
}
