import { faBrainCircuit } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Group } from "@mantine/core";
import {
  IconBuildingStore,
  IconDeviceDesktopAnalytics,
  IconFingerprint,
  IconGauge,
  IconHome2,
  IconReport,
  IconReportMoney,
  IconSettings,
  IconUser,
} from "@tabler/icons";

const appLinks = [
  {
    label: "Home",
    icon: IconHome2,
    links: [
      {
        label: "Home",
        to: "/",
      },
      {
        label: (
          <>
            <Group>
              <Badge color="violet" radius="sm" variant="dot">
                <FontAwesomeIcon icon={faBrainCircuit} />
                &nbsp;AI
              </Badge>
              <div>Purchasing™</div>
            </Group>
          </>
        ),
        to: "/ai/purchasing",
      },
      {
        label: (
          <>
            <Group>
              <Badge color="violet" radius="sm" variant="dot">
                <FontAwesomeIcon icon={faBrainCircuit} />
                &nbsp;AI
              </Badge>
              <div>Selling™</div>
            </Group>
          </>
        ),
        to: "/ai/selling",
      },
      {
        label: (
          <>
            <Group>
              <Badge color="violet" radius="sm" variant="dot">
                <FontAwesomeIcon icon={faBrainCircuit} />
                &nbsp;AI
              </Badge>
              <div>SideKick™</div>
            </Group>
          </>
        ),
        to: "/ai/side-kick",
      },
      {
        label: "Support",
        to: "/support",
      },
      {
        label: "Blog",
        to: "/blog",
      },
      {
        label: "Integrations",
        to: "/integrations",
      },
      {
        label: "API & Technology",
        to: "/api",
      },
    ],
  },
  {
    label: "Dashboards",
    icon: IconGauge,
    links: [
      {
        label: "Approval",
        to: "/dashboard/approval",
      },
      {
        label: "Fulfillment",
        to: "/dashboard/fulfillment",
      },
      {
        label: "Finance",
        to: "/dashboard/finance",
      },
      {
        label: "Shipments",
        to: "/dashboard/shipments",
      },
      {
        label: "Inventory",
        to: "/dashboard/inventory",
      },
    ],
  },
  {
    label: "Analytics",
    icon: IconDeviceDesktopAnalytics,
    links: [
      {
        label: "Supply Audits",
        to: "/analytics/supply-audits",
      },
      {
        label: "Machine Learning",
        to: "/analytics/machine-learning",
      },
    ],
  },
  {
    label: "Account",
    icon: IconUser,
    links: [
      {
        label: "Your Organization",
        to: "/organization",
      },
      {
        label: "Locations",
        to: "/locations",
      },
      {
        label: "Departments",
        to: "/departments",
      },
      {
        label: "Users",
        to: "/users",
      },
      {
        label: "Permissions",
        to: "/permissions",
      },
    ],
  },
  {
    label: "Purchasing",
    icon: IconReport,
    links: [
      {
        label: "MainStem Fricton-Free™",
        to: "/friction-free",
      },
      {
        label: "Vendors",
        to: "/vendors",
      },
      {
        label: "Products",
        to: "/products/purchasing",
      },
      {
        label: "Services",
        to: "/services/purchasing",
      },
      {
        label: "Requisitions",
        to: "/requisitions/purchasing",
      },
      {
        label: "Quotes",
        to: "/quotes/purchasing",
      },
      {
        label: "Purchase Orders",
        to: "/purchase-orders",
      },
      {
        label: "Invoices",
        to: "/invoices/purchasing",
      },
      {
        label: "Shipments",
        to: "/shipments/purchasing",
      },
      {
        label: "Receiving",
        to: "/receiving",
      },
    ],
  },
  {
    label: "Selling",
    icon: IconBuildingStore,
    links: [
      {
        label: "MainStem Opportunities™",
        to: "/opportunities",
      },
      {
        label: "Customers",
        to: "/customers",
      },
      {
        label: "Products",
        to: "/products/selling",
      },
      {
        label: "Services",
        to: "/services/selling",
      },
      {
        label: "Quotes",
        to: "/quotes/selling",
      },
      {
        label: "Sales Orders",
        to: "/sales-orders",
      },
      {
        label: "Invoices",
        to: "/invoices/selling",
      },
      {
        label: "Shipments",
        to: "/shipments/selling",
      },
    ],
  },
  {
    label: "Finances",
    icon: IconReportMoney,
    links: [
      {
        label: "MainStem Direct Pay™",
        to: "/direct-pay",
      },
      {
        label: "Budgets",
        to: "/budgets",
      },
      {
        label: "Bills",
        to: "/bills",
      },
      {
        label: "Payments",
        to: "/payments",
      },
      {
        label: "Returns & Refunds",
        to: "/returns-and-refunds",
      },
      {
        label: "Credit Cards",
        to: "/payment-methods/credit-cards",
      },
      {
        label: "ACH",
        to: "/payment-methods/ach",
      },
      {
        label: "Terms",
        to: "/payment-methods/terms",
      },
    ],
  },
  {
    label: "Security",
    icon: IconFingerprint,
    links: [
      {
        label: "Your Profile",
        to: "/profile",
      },
      {
        label: "Users",
        to: "/security/users",
      },
      {
        label: "Permissions",
        to: "/security/permissions",
      },
      {
        label: "Compliance",
        to: "/compliance",
      },
    ],
  },
  {
    label: "Settings",
    icon: IconSettings,
    links: [
      {
        label: "Account Settings",
        to: "/account-settings",
      },
      {
        label: "Billing Options",
        to: "/billing-options",
      },  {
        label: "Notification Options",
        to: "/settings/notifications",
      },
      {
        label: "Integrations",
        to: "/integrations/settings",
      },
    ],
  },
];
export { appLinks };
