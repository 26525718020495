// React
import { useEffect, useState } from "react";
// Routing
import { Link } from "react-router-dom";
// Mantine
import {
  Badge,
  Button,
  Card,
  Divider,
  Grid,
  Group,
  LoadingOverlay,
  Table,
  TextInput,
  Title,
} from "@mantine/core";
import {
  setNavigationProgress,
  startNavigationProgress,
  stopNavigationProgress,
} from "@mantine/nprogress";
// MainStem - API
import {
  Api,
  OrganizationListResponseOrganizationDetails,
} from "@mainstem/mainstem-api";
// Auth0
import { withAuthenticationRequired } from "@auth0/auth0-react";
// Icons
import {
  IconChevronRight,
  IconCloudComputing,
  IconSearch,
} from "@tabler/icons";
// Local - Components
import { CustomerCard, Header } from "./components";
import { PageTitle, theme } from "@mainstem/react-mainstem";

const PageCustomerList: React.FC = () => {
  const [customers, setCustomers] = useState<
    OrganizationListResponseOrganizationDetails[] | undefined
  >();
  const [doneLoading, setDoneLoading] = useState<boolean>(false);

  // Fires when page first loads
  useEffect(() => {
    startNavigationProgress();
    setNavigationProgress(25);
    // Prepare a new connection to the API
    const mainstemAPI = new Api();
    // Prepare our API request
    const apiRequest = {};
    // Attempt to call the API
    mainstemAPI.organization
      .organizationList(apiRequest)
      .then((apiResponse) => {
        setNavigationProgress(75);
        setCustomers(apiResponse.data.organizations);
      })
      .catch(() => {
        window.alert("API Failure");
      })
      .finally(() => {
        setDoneLoading(true);
        setNavigationProgress(100);
        stopNavigationProgress();
      });
  }, []);
  return (
    <>
      <div style={{ zIndex: 2, position: "relative" }}>
        <PageTitle
          image={theme.logos.mainstem.color}
          title="Customers You Sell To"
        />
      </div>
      <div style={{ marginTop: -10, zIndex: 1 }}>
        <Header />
      </div>
      <Divider my="md" />
      <Grid>
        <LoadingOverlay overlayBlur={2} visible={!doneLoading} />
        <Grid.Col md={8}>
          <Card withBorder shadow={"md"}>
            <Card.Section p="md">
              <Title order={2}>Manage Existing Customers</Title>
              <TextInput
                icon={<IconSearch />}
                placeholder="Search for a customer..."
                my="md"
              />
            </Card.Section>
            <Table striped highlightOnHover>
              <thead>
                <tr>
                  <th />
                  <th>ID</th>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                {customers?.map((customer) => {
                  return (
                    <tr key={customer.id}>
                      <td>
                        <Button
                          component={Link}
                          to={`/customers/details/${customer.id}`}
                          size="sm"
                          variant="default"
                        >
                          View Details
                        </Button>
                      </td>
                      <td>{customer.id}</td>
                      <td>{customer.name}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card>
        </Grid.Col>
        <Grid.Col md={4}>
          <Card withBorder shadow={"md"}>
            <Card.Section p="md">
              <Title order={2}>Find New Customers</Title>
              <Badge>
                <Group spacing={0}>
                  <IconCloudComputing size={14} />
                  <span>&nbsp;Engine Recomendations</span>
                </Group>
              </Badge>
            </Card.Section>
            <CustomerCard
              id="sdfsdfsd-sdfsdfsdf"
              image="https://mainstem.blob.core.windows.net/files/51862e7b-5cea-4530-9a8a-d33cce333509.png"
              name="A-Z Company"
              email="harriet@mainstem.io"
              icon={<IconChevronRight />}
            />
          </Card>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default withAuthenticationRequired(PageCustomerList, {
  onRedirecting: () => <LoadingOverlay visible />,
});
