// Routing
import { useNavigate } from "react-router-dom";
// Mantine
import {
  TextInput,
  PasswordInput,
  Text,
  Paper,
  Group,
  Button,
  Divider,
  Checkbox,
  Anchor,
  Stack,
  Center,
  Container,
} from "@mantine/core";
// Local - Components
import { IconGoogle, IconMicosoft } from "components";
import imgBackground from "assets/img/background.jpg";

const PageAuthRegister: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Center
      style={{
        backgroundSize: "100%",
        backgroundImage: `url(${imgBackground})`,
        height: "100vh",
        width: "100%",
      }}
    >
      <Container size={460}>
        <Paper radius="md" p="xl" withBorder>
          <Text size="lg" weight={500}>
            Welcome to MainStem, register with
          </Text>

          <Group grow mb="md" mt="md">
            <Button
              leftIcon={<IconMicosoft />}
              variant="default"
              color="gray"
              radius="xl"
            >
              Microsoft
            </Button>
            <Button
              leftIcon={<IconGoogle />}
              variant="default"
              color="gray"
              radius="xl"
            >
              Google
            </Button>
          </Group>

          <Divider
            label="Or continue with email"
            labelPosition="center"
            my="lg"
          />

          <Stack>
            <TextInput label="Name" placeholder="Your name" />
            <TextInput
              required
              label="Email"
              placeholder="you@company.com"
              onChange={(event) => {
                //form.setFieldValue("email", event.currentTarget.value)
              }}
            />

            <PasswordInput
              required
              label="Password"
              placeholder="Your password"
              // value={form.values.password}
              onChange={(event) => {
                // form.setFieldValue("password", event.currentTarget.value)
              }}
            />

            <Checkbox label="I accept terms and conditions" />
          </Stack>

          <Group position="apart" mt="xl">
            <Anchor
              component="button"
              type="button"
              color="dimmed"
              onClick={() => {
                navigate("/auth/login");
              }}
              size="xs"
            >
              Already have an account? Login
            </Anchor>
            <Button
              variant="gradient"
              gradient={{ from: "indigo", to: "cyan" }}
            >
              Register
            </Button>
          </Group>
        </Paper>
      </Container>
    </Center>
  );
};

export default PageAuthRegister;
