import { faListAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, PageTitle, theme } from "@mainstem/react-mainstem";
import { Divider, Grid, List, Title } from "@mantine/core";
import { ExampleData, PlaceholderStats } from "components";

const PageDirectPay: React.FC = () => {
  return (
    <>
      <PageTitle
        actions={
          <>
            <Button color="primary">
              <FontAwesomeIcon icon={faListAlt} />
              &nbsp;Apply Now!
            </Button>
          </>
        }
        image={theme.logos.mainstem.color}
        subtitle="Easily pay your vendors, or accept payments from customers directly on the MainStem platform."
        title="Direct Pay™"
      />

      <Grid>
        <Grid.Col span={6}>
          <PlaceholderStats
            data={[
              {
                title: "Accounts Payable",
                icon: "receipt",
                value: "$13,256.89",
                diff: 13,
              },
            ]}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <PlaceholderStats
            data={[
              {
                title: "Accounts Receivable",
                icon: "coin",
                value: "$213.11",
                diff: -2,
              },
            ]}
          />
        </Grid.Col>
      </Grid>
      <Divider my="md" />
      <Grid>
        <Grid.Col span={6}>
          <Title order={1}>You Pay MainStem</Title>
          <br />
          <List>
            <List.Item>Enable MainStem Direct Pay™ on your account</List.Item>
            <List.Item>
              Purchase from vendors that support MainStem Direct Pay™
            </List.Item>
            <List.Item>MainStem collects payment from you</List.Item>
            <List.Item>MainStem pays your vendors</List.Item>
          </List>
        </Grid.Col>
        <Grid.Col span={6}>
          <Title order={1}>MainStem Pays You</Title> <br />
          <List>
            <List.Item>Enable MainStem Direct Pay™ on your account</List.Item>
            <List.Item>Sell On the Platform</List.Item>
            <List.Item>MainStem collects payment from customer</List.Item>
            <List.Item>MainStem pays you directly</List.Item>
          </List>
        </Grid.Col>
      </Grid>
      <Divider my="md" />
      <ExampleData />
    </>
  );
};

export default PageDirectPay;
