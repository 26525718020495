// Routing
import { Link } from "react-router-dom";
// Mantine
import {
  Anchor,
  Button,
  Center,
  Container,
  Divider,
  Group,
  Paper,
  Text,
  Title,
  createStyles,
} from "@mantine/core";
// Auth0
import { useAuth0 } from "@auth0/auth0-react";
// Global - App Context
import imgBackground from "assets/img/background.jpg";
// Global - Components
import { IconGoogle, IconMicosoft } from "components";

const useStyles = createStyles((theme) => ({
  wrapper: {
    height: "100vh",
    minHeight: 900,
    backgroundSize: "contain",
    backgroundPosition: "right",
    backgroundImage: `url(${imgBackground})`,
  },

  form: {
    borderRight: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
    }`,
    height: "100vh",
    minHeight: 900,
    maxWidth: 450,
    paddingTop: 80,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: "100%",
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  logo: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    width: 120,
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

const PageAuthLogin: React.FC = () => {
  const { classes } = useStyles();
  const { loginWithRedirect } = useAuth0();

  const handleLogin = () => {
    loginWithRedirect();
  };

  return (
    <>
      <div className={classes.wrapper}>
        <Paper className={classes.form} radius={0} p={30}>
          <Center style={{ height: "100%", width: "100%" }}>
            <Container>
              <Title
                order={2}
                className={classes.title}
                align="center"
                mt="md"
                mb="md"
              >
                Welcome back to MainStem!
              </Title>
              <Group grow mb="md">
                <Button
                  leftIcon={<IconMicosoft />}
                  variant="default"
                  color="gray"
                  radius="xl"
                  onClick={() => {
                    handleLogin();
                  }}
                >
                  Microsoft
                </Button>
                <Button
                  leftIcon={<IconGoogle />}
                  variant="default"
                  color="gray"
                  radius="xl"
                  onClick={() => {
                    handleLogin();
                  }}
                >
                  Google
                </Button>
              </Group>
              <Divider
                label="Or login with email"
                labelPosition="center"
                my="lg"
              />
              <Button
                fullWidth
                mt="xl"
                onClick={() => {
                  handleLogin();
                }}
                size="md"
                variant="gradient"
                gradient={{ from: "indigo", to: "cyan" }}
              >
                Let's Login!
              </Button>
              <Text align="center" mt="md">
                Don&apos;t have an account?{" "}
                <Anchor component={Link} to="/auth/register" weight={700}>
                  Register
                </Anchor>
              </Text>
              <Text align="center" mt="md">
                <Anchor
                  component={Link}
                  to="/auth/password-reset/request"
                  weight={600}
                >
                  Forgot password?
                </Anchor>
              </Text>
            </Container>
          </Center>
        </Paper>
      </div>
    </>
  );
};

export default PageAuthLogin;
