// Mantine
import {
  Badge,
  Chip,
  Container,
  Divider,
  Group,
  Overlay,
  SimpleGrid,
  Text,
  Title,
  createStyles,
} from "@mantine/core";
import { openSpotlight } from "@mantine/spotlight";
// Icons - Tabler
import { IconSearch, IconX } from "@tabler/icons";
// Global - Assets - Image
import imgAlgoliaRecommended from "assets/img/logos/algolia-recommended.svg";
import { FrictionFreeSearch } from "components";
import { ProductCard } from "./components";

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
    paddingTop: 60,
    paddingBottom: 40,
    backgroundImage:
      "url(https://images.unsplash.com/photo-1618005182384-a83a8bd57fbe?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1064&q=80)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: 50,

    "@media (max-width: 520px)": {
      paddingTop: 80,
      paddingBottom: 50,
    },
  },

  inner: {
    position: "relative",
    zIndex: 1,
  },

  title: {
    fontWeight: 800,
    fontSize: 40,
    letterSpacing: -1,
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    color: theme.white,
    marginBottom: theme.spacing.xs,
    textAlign: "center",
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    textShadow: "0px 1px #000",

    "@media (max-width: 520px)": {
      fontSize: 28,
      textAlign: "left",
    },
  },

  highlight: {
    color: "#00b5e2",
    fontWeight: 600,
    textShadow: "0px 1px #440099",
  },

  description: {
    color: theme.colors.gray[0],
    textAlign: "center",
    textShadow: "0px 1px #000",

    "@media (max-width: 520px)": {
      fontSize: theme.fontSizes.md,
      textAlign: "left",
    },
  },

  controls: {
    marginTop: theme.spacing.xl * 1.5,
    display: "flex",
    justifyContent: "center",
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,

    "@media (max-width: 520px)": {
      flexDirection: "column",
    },
  },

  control: {
    height: 42,
    width: "100%",
    fontSize: theme.fontSizes.md,

    "&:not(:first-of-type)": {
      marginLeft: theme.spacing.md,
    },

    "@media (max-width: 520px)": {
      "&:not(:first-of-type)": {
        marginTop: theme.spacing.md,
        marginLeft: 0,
      },
    },
  },

  secondaryControl: {
    color: theme.white,
    backgroundColor: "rgba(255, 255, 255, .4)",

    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, .45) !important",
    },
  },
}));

const PageFrictionFree: React.FC = () => {
  const { classes } = useStyles();

  const products = [
    {
      id: 1,
      name: "CYCO Zyme 205 Liter (1/Cs)",
      image:
        "https://scotts-hawthorne-public-prod.s3-us-west-2.amazonaws.com/PROD/imageMedia/part/medium/HGC760778-01.jpg",
      url: "https://shop.mainstem.io/product/45701/cyco-zyme-205-liter-1cs",
    },
    {
      id: 2,
      name: "Cart Card – Child Resistant Cartridge Box – (100 qty)",
      image:
        "https://mainstemcdn.azureedge.net/files/ae0e1732-cdb9-4dbc-b1ac-9ff851416e56.jpg",
      url: "https://shop.mainstem.io/product/28045/cart-card--child-resistant-cartridge-box--100-qty",
    },
    {
      id: 3,
      name: "Green tCheck Infusion Potency Tester",
      image:
        "https://mainstemcdn.azureedge.net/files/ee1db51a-9ae0-4cc0-a819-13b6556c372e.png",
      url: "https://shop.mainstem.io/product/34356/green-tcheck-infusion-potency-tester--",
    },
    {
      id: 4,
      name: "Sun System 1000 Watt DE Boss Commercial Fixture 277 Volt",
      image:
        "https://scotts-hawthorne-public-prod.s3-us-west-2.amazonaws.com/PROD/imageMedia/part/medium/HGC906612-01.jpg",
      url: "https://shop.mainstem.io/product/46334/sun-system-1000-watt-de-boss-commercial-fixture-277-volt",
    },
  ];

  return (
    <>
      <div className={classes.wrapper}>
        <Overlay color="#000" opacity={0.05} zIndex={1} radius={50} />

        <div className={classes.inner}>
          <Title className={classes.title}>
            Search for&nbsp;
            <Text component="span" inherit className={classes.highlight}>
              products, services and vendors.
            </Text>
          </Title>

          <Container size={640}>
            <Text size="lg" className={classes.description}>
              Search more reliably with{" "}
              <Text component="span" inherit className={classes.highlight}>
                MainStem's AI companion
              </Text>
              . AI is trained to find the best matches for your search.
            </Text>
          </Container>
        </div>
      </div>
      <Divider my="md" />
      <div style={{ margin: "0px 25px" }}>
        <FrictionFreeSearch />
      </div>
      <Divider my="md" />
      <Text my="md" color="dimmed">
        Recent Search
      </Text>
      <Chip.Group>
        <Chip
          onClick={() => {
            openSpotlight();
          }}
        >
          <Group spacing={5}>
            <span>19</span>
            <IconX size={14} />
          </Group>
        </Chip>
        <Chip
          onClick={() => {
            openSpotlight();
          }}
        >
          <Group spacing={5}>
            <span>DRAM</span>
            <IconX size={14} />
          </Group>
        </Chip>
        <Chip
          onClick={() => {
            openSpotlight();
          }}
        >
          <Group spacing={5}>
            <span>Red</span>
            <IconX size={14} />
          </Group>
        </Chip>
        <Chip
          onClick={() => {
            openSpotlight();
          }}
        >
          <Group spacing={5}>
            <span>Pop</span>
            <IconX size={14} />
          </Group>
        </Chip>
        <Chip
          onClick={() => {
            openSpotlight();
          }}
        >
          <Group spacing={5}>
            <span>Tops</span>
            <IconX size={14} />
          </Group>
        </Chip>
      </Chip.Group>
      <Divider my="md" />
      <Text my="md" color="dimmed">
        Suggested Searches
        <Badge color="grape" ml="sm" variant="light">
          MainStem Nudge™
        </Badge>
      </Text>
      <Chip.Group>
        <Chip
          onClick={() => {
            openSpotlight();
          }}
        >
          <Group spacing={5}>
            <span>Child Resistant Pop Tops</span>
            <IconSearch size={14} />
          </Group>
        </Chip>
        <Chip
          onClick={() => {
            openSpotlight();
          }}
        >
          <Group spacing={5}>
            <span>HydroFarm</span>
            <IconSearch size={14} />
          </Group>
        </Chip>
        <Chip
          onClick={() => {
            openSpotlight();
          }}
        >
          <Group spacing={5}>
            <span>280E Accounting Specialist</span>
            <IconSearch size={14} />
          </Group>
        </Chip>
      </Chip.Group>
      <Divider my="md" />
      <Group>
        <Text color="dimmed">Recommended for you</Text>
        <a
          href="https://www.algolia.com/products/recommendations/"
          target={"_blank"}
          rel="noopener noreferrer"
          style={{
            cursor: "pointer",
            textDecoration: "none",
          }}
        >
          <span>
            <Badge style={{ cursor: "pointer" }} color="violet" variant="light">
              Powered By{" "}
            </Badge>
            <img
              alt="Logo"
              src={imgAlgoliaRecommended}
              style={{ height: 20 }}
            />
          </span>
        </a>
      </Group>
      <SimpleGrid cols={4} mt="md">
        {products.map((product) => {
          return <ProductCard key={product.id} product={product} />;
        })}
      </SimpleGrid>
    </>
  );
};

export default PageFrictionFree;
