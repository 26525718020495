// MainStem
import { Button } from "@mainstem/react-mainstem";
// Mantine
import {
  Badge,
  Card,
  Center,
  Group,
  Image,
  Text,
  createStyles,
} from "@mantine/core";
import {
  IconGasStation,
  IconGauge,
  IconManualGearbox,
  IconReceipt,
  IconShieldCheck,
  IconUsers,
} from "@tabler/icons";
import { IProductCard } from "./types";

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
  },

  imageSection: {
    padding: theme.spacing.md,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  label: {
    marginBottom: theme.spacing.xs,
    lineHeight: 1,
    fontWeight: 700,
    fontSize: theme.fontSizes.xs,
    letterSpacing: -0.25,
    textTransform: "uppercase",
  },

  section: {
    padding: theme.spacing.md,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  icon: {
    marginRight: 5,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[2]
        : theme.colors.gray[5],
  },
}));

const mockdata = [
  { label: "4 passengers", icon: IconUsers },
  { label: "100 km/h in 4 seconds", icon: IconGauge },
  { label: "Automatic gearbox", icon: IconManualGearbox },
  { label: "Electric", icon: IconGasStation },
];

const ProductCard: React.FC<IProductCard> = ({ product }: IProductCard) => {
  const { classes } = useStyles();
  const features = mockdata.map((feature) => (
    <Center key={feature.label}>
      <feature.icon size={18} className={classes.icon} stroke={1.5} />
      <Text size="xs">{feature.label}</Text>
    </Center>
  ));

  return (
    <Card withBorder radius="md" className={classes.card}>
      <Card.Section className={classes.imageSection}>
        <Image src={product.image} alt="Tesla Model S" height={100} />
      </Card.Section>

      <Group position="apart" mt="md">
        <div>
          <Text weight={500}>{product.name}</Text>
          <Text size="xs" color="dimmed">
            Free recharge at any station
          </Text>
        </div>
        <Badge color="violet" variant="outline">
          <Group spacing={0}>
            <IconShieldCheck size={16} />
            &nbsp;In-Network Supplier
          </Group>
        </Badge>
      </Group>

      <Card.Section className={classes.section} mt="md">
        <Text size="sm" color="dimmed" className={classes.label}>
          Basic configuration
        </Text>

        <Group spacing={8} mb={-8}>
          {features}
        </Group>
      </Card.Section>

      <Card.Section className={classes.section}>
        <Group spacing={30}>
          <div>
            <Text size="xl" weight={700} sx={{ lineHeight: 1 }}>
              $168.00
            </Text>
            <Text
              size="sm"
              color="dimmed"
              weight={500}
              sx={{ lineHeight: 1 }}
              mt={3}
            >
              per unit
            </Text>
          </div>

          <Button round style={{ flex: 1 }}>
            <IconReceipt size={12} />
            &nbsp;Purchase Now
          </Button>
        </Group>
      </Card.Section>
    </Card>
  );
};

export { ProductCard };
