import { PageTitle, theme } from "@mainstem/react-mainstem";
import { Divider } from "@mantine/core";
import { ExampleData } from "components";

const PageAISelling: React.FC = () => {
  return (
    <>
      <PageTitle
        image={theme.logos.mainstem.color}
        subtitle="Sell your products and services with ease using our AI-powered selling platform."
        title="[AI] - Selling™"
      />
      <Divider my="md" />
      <ExampleData />
    </>
  );
};

export default PageAISelling;
