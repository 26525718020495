import { PageTitle, theme } from "@mainstem/react-mainstem";
import {
  createStyles,
  Text,
  Title,
  Anchor,
  Code,
  Group,
  Image,
  Divider,
} from "@mantine/core";
import imgAPI from "./images/api.png";
import imgNPM from "./images/npm.png";
import imgOAuth2 from "./images/oauth2.png";
import imgStorybook from "./images/storybook.png";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing.xl * 2,
    borderRadius: theme.radius.md,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[3]
    }`,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      flexDirection: "column-reverse",
      padding: theme.spacing.xl,
    },
  },

  image: {
    maxWidth: "40%",

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: "100%",
    },
  },

  body: {
    paddingRight: theme.spacing.xl * 4,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      paddingRight: 0,
      marginTop: theme.spacing.xl,
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1,
    marginBottom: theme.spacing.md,
  },

  controls: {
    display: "flex",
    marginTop: theme.spacing.xl,
  },

  inputWrapper: {
    width: "100%",
    flex: "1",
  },

  input: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: 0,
  },

  control: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}));

const PageAPI: React.FC = () => {
  const { classes } = useStyles();
  return (
    <>
      <PageTitle image={theme.logos.mainstem.color} title="API & Technology" />
      <Divider my="md" />
      <div className={classes.wrapper}>
        <div className={classes.body}>
          <Group>
            <div
              style={{
                width: 240,
                marginLeft: "auto",
                marginRight: "auto",
                border: "1px solid #e8e8e8",
              }}
            >
              <Image
                radius="md"
                src={imgOAuth2}
                alt="Random unsplash image"
              />
            </div>
            <div>
              <Title className={classes.title}>
                MainStem OAuth 2 - Single Sign On
              </Title>
              <Text weight={500} size="lg" mb={5}>
                OAuth 2.0 Single Sign On
              </Text>
              <Text size="sm" color="dimmed">
                Use MainStem's OAuth 2.0 Single Sign On to authenticate your app
                and API.
              </Text>
              <Text mt="md">
                <Anchor
                  target={"_blank"}
                  href={"https://storybook.mainstem.io"}
                >
                  View the OAuth/SSO Details Here
                </Anchor>
              </Text>
            </div>
          </Group>
        </div>
        {/* <Image src={image.src} className={classes.image} /> */}
      </div>
      <br />
      <div className={classes.wrapper}>
        <div className={classes.body}>
          <Group>
            <div
              style={{
                width: 240,
                marginLeft: "auto",
                marginRight: "auto",
                border: "1px solid #e8e8e8",
              }}
            >
              <Image radius="md" src={imgAPI} alt="Random unsplash image" />
            </div>
            <div>
              <Title className={classes.title}>MainStem OpenAPI</Title>
              <Text weight={500} size="lg" mb={5}>
                OpenAPI Specification 3.1
              </Text>
              <Text size="sm" color="dimmed">
                MainStem's robust API allows you to directly tap into your
                MainStem account.
              </Text>
              <Text mt="md">
                <Anchor
                  target={"_blank"}
                  href={"https://mainstem-api-docs.mainstem.io/api/swagger/ui"}
                >
                  View the API Documentation Here
                </Anchor>
              </Text>
            </div>
          </Group>
        </div>
        {/* <Image src={image.src} className={classes.image} /> */}
      </div>
      <br />
      <div className={classes.wrapper}>
        <div className={classes.body}>
          <Group>
            <div
              style={{
                width: 240,
                marginLeft: "auto",
                marginRight: "auto",
                border: "1px solid #e8e8e8",
              }}
            >
              <Image radius="md" src={imgNPM} alt="Random unsplash image" />
            </div>
            <div>
              <Title className={classes.title}>MainStem API NPM Package</Title>
              <Text weight={500} size="lg" mb={5}>
                Strongly type TypeScript client library for the MainStem API
              </Text>
              <Text size="sm" color="dimmed">
                MainStem's robust API allows you to directly tap into your
                MainStem account.
              </Text>
              <Code>yarn install @mainstem/mainstem-api</Code>
              <Text mt="md">
                <Anchor
                  target={"_blank"}
                  href={"https://www.npmjs.com/package/@mainstem/mainstem-api"}
                >
                  View the NPM Package Here
                </Anchor>
              </Text>
            </div>
          </Group>
        </div>
        {/* <Image src={image.src} className={classes.image} /> */}
      </div>
      <br />
      <div className={classes.wrapper}>
        <div className={classes.body}>
          <Group>
            <div
              style={{
                width: 240,
                marginLeft: "auto",
                marginRight: "auto",
                border: "1px solid #e8e8e8",
              }}
            >
              <Image
                radius="md"
                src={imgStorybook}
                alt="Random unsplash image"
              />
            </div>
            <div>
              <Title className={classes.title}>
                MainStem React Component Library
              </Title>
              <Text weight={500} size="lg" mb={5}>
                TypeScript, Styled-Components, and React Component Library
              </Text>
              <Text size="sm" color="dimmed">
                Use MainStem's robust component library to build your own custom
                app.
              </Text>
              <Code>yarn install @mainstem/react-mainstem</Code>
              <Text mt="md">
                <Anchor
                  target={"_blank"}
                  href={"https://storybook.mainstem.io"}
                >
                  View the StoryBook Here
                </Anchor>
              </Text>
            </div>
          </Group>
        </div>
        {/* <Image src={image.src} className={classes.image} /> */}
      </div>
    </>
  );
};

export default PageAPI;
