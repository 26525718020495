import { PageTitle, theme } from "@mainstem/react-mainstem";
import { ExampleData } from "components";
import { Header } from "./components";

const PageOpportunities: React.FC = () => {
  return (
    <>
      <PageTitle image={theme.logos.mainstem.color} title="Opportunities™" />
      <Header />
      <ExampleData />
    </>
  );
};

export default PageOpportunities;
