import imgBigCommerce from "assets/img/integrations/bigcommerce.jpg";
import imgBiotrack from "assets/img/integrations/biotrack.jpg";
import imgD6 from "assets/img/integrations/d6.jpg";
import imgEDI from "assets/img/integrations/edi.jpg";
import imgExcel from "assets/img/integrations/excel.jpg";
import imgHathorne from "assets/img/integrations/hawthorne.jpg";
import imgHydroFarm from "assets/img/integrations/hydrofarm.jpg";
import imgLeafLogix from "assets/img/integrations/leaflogix.jpg";
import imgLendica from "assets/img/integrations/lendica.jpg";
import imgPayQwick from "assets/img/integrations/payqwick.jpg";
import imgPowerBI from "assets/img/integrations/powerbi.jpg";
import imgPowerQuery from "assets/img/integrations/powerquery.jpg";
import imgQuickBooksOnline from "assets/img/integrations/quickbooksonline.jpg";
import imgSageIntacct from "assets/img/integrations/sageintacct.jpg";
import imgShopify from "assets/img/integrations/shopify.jpg";
import imgSlack from "assets/img/integrations/slack.jpg";
import imgSnowflake from "assets/img/integrations/snowflake.jpg";
import imgTransactionPro from "assets/img/integrations/transactionpro.jpg";
import imgWeedTraQR from "assets/img/integrations/weedtraqr.jpg";
import imgWooCommerce from "assets/img/integrations/woocommerce.jpg";

import { IIntegration } from "../types";

const integrations: Array<IIntegration> = [
  {
    name: "Excel",
    company: "Microsoft",
    description:
      "Sync your MainStem account data to Excel and get real-time updates on your supply chain.",
    developer: "MainStem",
    image: imgExcel,
    to: "excel",
    disabled: false,
    tags: [
      {
        label: "Data",
        icon: "",
      },
    ],
  },
  {
    name: "PowerBI",
    company: "Microsoft",
    description:
      "Sync your MainStem data with PowerBI and create custom reports.",
    developer: "MainStem",
    image: imgPowerBI,
    to: "power-bi",
    disabled: false,
    tags: [
      {
        label: "Data",
        icon: "",
      },
    ],
  },
  {
    name: "Snowflake",
    company: "Snowflake",
    description: "Tap into your MainStem data inside a Snowflake data cloud.",
    developer: "MainStem",
    image: imgSnowflake,
    to: "snowflake",
    disabled: false,
    tags: [
      {
        label: "Data",
        icon: "",
      },
    ],
  },
  {
    name: "QuickBooks Online",
    company: "Intuit",
    description:
      "Tap into your MainStem data inside a QuickBooks Online account.",
    developer: "MainStem",
    image: imgQuickBooksOnline,
    to: "quickbooks-online",
    disabled: false,
    tags: [
      {
        label: "Accounting",
        icon: "",
      },
    ],
  },
  {
    name: "Lendica",
    company: "Lendica",
    description: "Pay your suppliers on your terms.",
    developer: "MainStem",
    image: imgLendica,
    to: "lendica",
    disabled: false,
    tags: [
      {
        label: "Financial Services",
        icon: "",
      },
    ],
  },
  {
    name: "Slack",
    company: "Slack",
    description: "Get real time updates about your supply chain.",
    developer: "MainStem",
    image: imgSlack,
    to: "slack",
    disabled: false,
    tags: [
      {
        label: "Communication",
        icon: "",
      },
    ],
  },
  {
    name: "EDI",
    company: "EDI",
    description:
      "Create EDI 850 documents when an order is placed on your MainStem products.",
    developer: "MainStem",
    image: imgEDI,
    to: "edi",
    disabled: false,
    tags: [
      {
        label: "API",
        icon: "",
      },
    ],
  },
  {
    name: "Shopify",
    company: "Shopify",
    description:
      "Sell your Shopify products on MainStem and receive real-time orders.",
    developer: "MainStem",
    image: imgShopify,
    to: "shopify",
    disabled: false,
    tags: [
      {
        label: "E-Commerce",
        icon: "",
      },
    ],
  },
  {
    name: "WooCommerce",
    company: "WooCommerce",
    description:
      "Sell your WooCommerce products on MainStem and receive real-time orders.",
    developer: "MainStem",
    image: imgWooCommerce,
    to: "woocommerce",
    disabled: false,
    tags: [
      {
        label: "E-Commerce",
        icon: "",
      },
    ],
  },
  {
    name: "Domain6",
    company: "Domain6",
    description: "Sync your MainStem data with your Microsoft Dynamics ERP.",
    developer: "Domain 6",
    image: imgD6,
    to: "d6",
    disabled: false,
    tags: [
      {
        label: "ERP",
        icon: "",
      },
    ],
  },
  {
    name: "PayQwick",
    company: "PayQwick",
    description: "Use your PayQwick account to pay your MainStem invoices.",
    developer: "MainStem",
    image: imgPayQwick,
    to: "payqwick",
    disabled: false,
    tags: [
      {
        label: "Financial Services",
        icon: "",
      },
    ],
  },
  {
    name: "PowerQuery",
    company: "Microsoft",
    description:
      "Access pre-built PowerQuery commands to tap into your MainStem data through our API.",
    developer: "MainStem",
    image: imgPowerQuery,
    to: "powerquery",
    disabled: false,
    tags: [
      {
        label: "Data",
        icon: "",
      },
    ],
  },
  {
    name: "Sage Intacct",
    company: "Sage",
    description: "",
    developer: "MainStem",
    image: imgSageIntacct,
    to: "sage/intacct",
    disabled: false,
    tags: [
      {
        label: "Accounting",
        icon: "",
      },
    ],
  },
  {
    name: "TransactionPro",
    company: "TransactionPro",
    description:
      "Receive weekly emailed CSV's of your transactions so you can directly import into TransactionPro.",
    developer: "MainStem",
    image: imgTransactionPro,
    to: "transactionpro",
    disabled: false,
    tags: [
      {
        label: "Accounting",
        icon: "",
      },
    ],
  },
  {
    name: "LeafLogix",
    company: "LeafLogix",
    description:
      "Receive inventory into LeafLogix and view your entire organizations inventory.",
    developer: "MainStem",
    image: imgLeafLogix,
    to: "leaf-logix",
    disabled: false,
    tags: [
      {
        label: "ERP",
        icon: "",
      },
    ],
  },
  {
    name: "BioTrack",
    company: "BioTrack",
    description: "Access your MainStem account directly from within BioTrack.",
    developer: "MainStem",
    image: imgBiotrack,
    to: "biotrack",
    disabled: false,
    tags: [
      {
        label: "ERP",
        icon: "",
      },
    ],
  },
  {
    name: "WeedTraQR",
    company: "WeedTraQR",
    description: "Access your MainStem account directly from within WeedTraQR.",
    developer: "MainStem",
    image: imgWeedTraQR,
    to: "weedtraqr",
    disabled: false,
    tags: [
      {
        label: "ERP",
        icon: "",
      },
    ],
  },
  {
    name: "BigCommerce",
    company: "BigCommerce",
    description:
      "Sell your BigCommerce products on MainStem and receive real-time orders.",
    developer: "MainStem",
    image: imgBigCommerce,
    to: "bigcommerce",
    disabled: false,
    tags: [
      {
        label: "E-Commerce",
        icon: "",
      },
    ],
  },
  {
    name: "Hawthorne",
    company: "Hawthorne",
    description:
      "Sync Hawthornes inventory with MainStem and create real-time orders.",
    developer: "MainStem",
    image: imgHathorne,
    to: "hawthorne",
    disabled: false,
    tags: [
      {
        label: "Distributor",
        icon: "",
      },
    ],
  },
  {
    name: "HydroFarm",
    company: "HydroFarm",
    description:
      "Sync HydroFarm's inventory with MainStem and create real-time orders.",
    developer: "MainStem",
    image: imgHydroFarm,
    to: "hydrofarm",
    disabled: false,
    tags: [
      {
        label: "Distributor",
        icon: "",
      },
    ],
  },
];

export { integrations };
