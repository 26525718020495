import { PageTitle, theme } from "@mainstem/react-mainstem";
import { Divider } from "@mantine/core";
import { ExampleData } from "components";

const PageBlog: React.FC = () => {
  return (
    <>
      <PageTitle image={theme.logos.mainstem.color} title="Blog" />
      <Divider my="md" />
      <ExampleData />
    </>
  );
};

export default PageBlog;
