// Routing
import { Link } from "react-router-dom";
// Mantine
import {
  ActionIcon,
  Badge,
  Button,
  Card,
  Group,
  Image,
  Text,
  createStyles,
} from "@mantine/core";
// Icons
import { IconHeart } from "@tabler/icons";
// Local - TypeScript Types
import { IIntegration } from "../../types";

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
  },

  section: {
    borderBottom: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    paddingBottom: theme.spacing.md,
  },

  like: {
    color: theme.colors.red[6],
  },

  label: {
    textTransform: "uppercase",
    fontSize: theme.fontSizes.xs,
    fontWeight: 700,
  },
}));

const IntegrationCard: React.FC<IIntegration> = ({
  name,
  company,
  description,
  image,
  to,
  disabled,
  tags,
}: IIntegration) => {
  const { classes, theme } = useStyles();

  return (
    <>
      <Card withBorder radius="md" p="md" className={classes.card}>
        <Card.Section>
          <Image src={image} alt={name} height={180} />
        </Card.Section>

        <Card.Section className={classes.section} mt="md">
          <Group position="apart">
            <Text size="lg" weight={500}>
              {name}
            </Text>
            <Badge size="sm">{company}</Badge>
          </Group>
          <Text size="sm" mt="xs">
            {description}
          </Text>
        </Card.Section>

        <Card.Section className={classes.section}>
          <Group spacing={7} mt={"md"}>
            {tags.map((tag) => {
              return (
                <Badge
                  color={theme.colorScheme === "dark" ? "dark" : "gray"}
                  key={tag.label}
                  leftSection={tag.icon}
                >
                  {tag.label}
                </Badge>
              );
            })}
          </Group>
        </Card.Section>
        <Group mt="xs">
          <Button
            component={Link}
            disabled={disabled}
            radius="md"
            style={{ flex: 1 }}
            variant="outline"
            to={`/integrations/${to}`}
          >
            Show details
          </Button>
          <ActionIcon variant="default" radius="md" size={36}>
            <IconHeart size={18} className={classes.like} stroke={1.5} />
          </ActionIcon>
        </Group>
      </Card>
    </>
  );
};
export { IntegrationCard };
