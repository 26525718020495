// Mantine
import {
  Skeleton,
  Grid,
  Card,
  Group,
  Anchor,
  Text,
  createStyles,
} from "@mantine/core";
// Local - Components
import { Services, Stats } from "./components";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGears } from "@fortawesome/pro-light-svg-icons";

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
  },

  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: theme.radius.md,
    height: 90,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    transition: "box-shadow 150ms ease, transform 100ms ease",

    "&:hover": {
      boxShadow: `${theme.shadows.md} !important`,
      transform: "scale(1.05)",
    },
  },
}));

const PageHome: React.FC = () => {
  const { classes, theme } = useStyles();

  const PRIMARY_COL_HEIGHT = 300;
  const SECONDARY_COL_HEIGHT = PRIMARY_COL_HEIGHT / 2 - theme.spacing.md / 2;

  return (
    <>
      <Anchor size="xs" color="dimmed">
        <FontAwesomeIcon icon={faGears} />
        &nbsp;Customize Dashboard
      </Anchor>
      <Grid columns={3}>
        <Grid.Col md={2} sm={3}>
          <Grid>
            <Grid.Col>
              <Stats />
            </Grid.Col>
          </Grid>
          <Grid columns={2}>
            <Grid.Col span={1}>
              <Card withBorder radius="md" className={classes.card}>
                <Group position="apart">
                  <Text className={classes.title}>
                    <FormattedMessage
                      id="purchasing"
                      defaultMessage="Purchasing"
                    />
                  </Text>
                  <Anchor size="xs" color="dimmed" sx={{ lineHeight: 1 }}>
                    + 2 other services
                  </Anchor>
                </Group>
                <Skeleton
                  height={PRIMARY_COL_HEIGHT}
                  radius="md"
                  animate={false}
                  mt="md"
                />
              </Card>
            </Grid.Col>
            <Grid.Col span={1}>
              <Card withBorder radius="md" className={classes.card}>
                <Group position="apart">
                  <Text className={classes.title}>
                    <FormattedMessage id="selling" defaultMessage="Selling" />
                  </Text>
                  <Anchor size="xs" color="dimmed" sx={{ lineHeight: 1 }}>
                    + 4 other services
                  </Anchor>
                </Group>
                <Skeleton
                  height={PRIMARY_COL_HEIGHT}
                  radius="md"
                  animate={false}
                  mt="md"
                />
              </Card>
            </Grid.Col>
          </Grid>
          <br />
          <Grid>
            <Grid.Col>
              <Text className={classes.title}>
                <FormattedMessage
                  id="aiRecommendations"
                  defaultMessage="[AI] - Recommendations"
                />
              </Text>
            </Grid.Col>
            <Grid.Col>
              <Skeleton
                height={PRIMARY_COL_HEIGHT}
                radius="md"
                animate={false}
              />
            </Grid.Col>
          </Grid>
        </Grid.Col>
        <Grid.Col md={1} sm={3}>
          <Grid>
            <Grid.Col>
              <Services />
            </Grid.Col>
            <Grid.Col>
              <Text className={classes.title}>
                <FormattedMessage
                  id="fromTheBlog"
                  defaultMessage="From The Blog"
                />
              </Text>
            </Grid.Col>
            <Grid.Col span={6}>
              <Skeleton
                height={SECONDARY_COL_HEIGHT}
                radius="md"
                animate={false}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Skeleton
                height={SECONDARY_COL_HEIGHT}
                radius="md"
                animate={false}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Skeleton
                height={SECONDARY_COL_HEIGHT}
                radius="md"
                animate={false}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Skeleton
                height={SECONDARY_COL_HEIGHT}
                radius="md"
                animate={false}
              />
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default PageHome;
