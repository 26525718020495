// Custom CSS
import "./index.css";
// React
import { useEffect, useState } from "react";
// MainStem - API
import { Api, PowerBIGetTokenRequest } from "@mainstem/mainstem-api";
// PowerBI
import { models } from "powerbi-client";
// import * as pbi from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import { config } from "../../config";

const ExampleReport = () => {
  const [token, setToken] = useState<string | undefined>("");

  useEffect(() => {
    const mainStemAPI = new Api();
    const request = {} as PowerBIGetTokenRequest;
    mainStemAPI.powerBi.powerBiGetToken(request).then((response: any) => {
      setToken(response.data.token);
    });
  }, []);

  return (
    <>
      <PowerBIEmbed
        cssClassName={"report-style-class"}
        embedConfig={{
          type: "report", // Supported types: report, dashboard, tile, visual and qna
          id: config.powerBi.reportId,
          embedUrl: config.powerBi.embedUrl,
          accessToken: token,
          tokenType: models.TokenType.Embed,
          settings: {
            panes: {
              filters: {
                expanded: false,
                visible: true,
              },
            },
            background: models.BackgroundType.Transparent,
          },
        }}
      />
    </>
  );
};

export { ExampleReport };
