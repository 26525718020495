// React
import { useEffect, useState } from "react";
// Routing
import { Link } from "react-router-dom";
// Mantine
import { Button, Divider, LoadingOverlay, Table } from "@mantine/core";
import {
  setNavigationProgress,
  startNavigationProgress,
  stopNavigationProgress,
} from "@mantine/nprogress";
// MainStem - API
import {
  Api,
  LocationListResponseLocationDetails,
} from "@mainstem/mainstem-api";

import { withAuthenticationRequired } from "@auth0/auth0-react";
import { PageTitle, theme } from "@mainstem/react-mainstem";
import { ExampleData } from "components";

const PageLocationsList: React.FC = () => {
  const [locations, setLocations] = useState<
    LocationListResponseLocationDetails[] | undefined
  >();
  const [doneLoading, setDoneLoading] = useState<boolean>(false);

  // Fires when page first loads
  useEffect(() => {
    startNavigationProgress();
    setNavigationProgress(25);
    // Prepare a new connection to the API
    const mainstemAPI = new Api();
    // Prepare our API request
    const apiRequest = {};
    // Attempt to call the API
    mainstemAPI.location
      .locationList(apiRequest)
      .then((apiResponse) => {
        setNavigationProgress(75);
        setLocations(apiResponse.data.locations);
      })
      .catch(() => {
        window.alert("API Failure");
      })
      .finally(() => {
        setDoneLoading(true);
        setNavigationProgress(100);
        stopNavigationProgress();
      });
  }, []);
  return (
    <>
      <PageTitle image={theme.logos.mainstem.color} title="Locations" />
      <br />
      <div style={{ width: "100%", position: "relative" }}>
        <LoadingOverlay overlayBlur={2} visible={!doneLoading} />
        <Table striped highlightOnHover>
          <caption>List Of All Locations</caption>
          <thead>
            <tr>
              <th />
              <th>ID</th>
              <th>Organization</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {locations?.map((location) => {
              return (
                <tr key={location.id}>
                  <td>
                    <Button
                      component={Link}
                      to={`/locations/details/${location.id}`}
                      size="sm"
                      variant="default"
                    >
                      View Details
                    </Button>
                  </td>
                  <td>{location.id}</td>
                  <td>{location.organization?.name}</td>
                  <td>{location.name}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Divider my="md" />
        <ExampleData />
      </div>
    </>
  );
};

export default withAuthenticationRequired(PageLocationsList, {
  onRedirecting: () => <LoadingOverlay visible />,
});
