// Mantine
import { PageTitle, theme } from "@mainstem/react-mainstem";
import { Badge, Divider, Group } from "@mantine/core";
import { IconFileAnalytics } from "@tabler/icons";
// Global - Components
import { ExampleReport } from "components";

const PageDashboardFinance: React.FC = () => {
  return (
    <>
      <PageTitle
        image={theme.logos.mainstem.color}
        title="Finance"
        subtitle="Analytics by Microsoft PowerBI Embedded"
      />
      <Divider
        labelPosition="center"
        label={
          <Badge color="violet" variant="light">
            <Group spacing={0}>
              <IconFileAnalytics size={14} />
              &nbsp;Analytics by Microsoft Power BI Embedded
            </Group>
          </Badge>
        }
        my="md"
      />
      <ExampleReport />
    </>
  );
};

export default PageDashboardFinance;
