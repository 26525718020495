import styled from "styled-components";

export const SCFrictionFreeSearchInputContainer = styled.div`
  position: relative;
`;

export const SCFrictionFreeSearchInput = styled.input`
  background-color: #fff;
  border: 0 none;
  border-bottom: 1px solid #e6e6e6;
  border-radius: 99px;
  border-top: 1px solid #e6e6e6;
  font-family: "Poppins", sans-serif;
  height: 50px;
  margin-bottom: 0;
  padding: 0 20px 0 40px;
  position: relative;
  width: 100%;
  z-index: 1;
`;

export const SCFrictionFreeSearchInputLoader = styled.div`
  left: 15px;
  position: absolute;
  top: 15px;
  z-index: 2;
`;

export const SCFrictionFreeSearchResults = styled.div`
  background-color: #fff;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 0 3px 0 rgb(0 0 0 / 57%);
  margin: 0 20px;
  padding: 20px;
  z-index: -1;
`;

export const SCFrictionFreeSearchResultsCount = styled.span`
  background-color: #fbfbfb;
  border: 1px solid #ecf2f9;
  border-radius: 5px;
  color: #9f9f9f;
  font-family: Poppins, sans-serif;
  font-size: 10px;
  left: 50px;
  line-height: 10px;
  margin-top: -10px;
  padding: 5px;
  position: absolute;
  z-index: 2;
`;

export const SCFrictionFreeSearchResult = styled.a`
  align-items: center;
  color: #010101;
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin: 10px 0;

  :hover {
    background-color: #ecf9fd;
    cursor: pointer;
    text-decoration: none;

    em {
      color: #0056b3;
    }
  }
`;

export const SCFrictionFreeSearchResultProductName = styled.span`
  font-family: Poppins, sans-serif;

  em {
    color: #000;
    font-weight: 600;
  }
`;

export const SCFrictionFreeSearchResultProductImageContainer = styled.div`
  height: 25px;
  margin-right: 15px;
  text-align: center;
  width: 25px;
`;

export const SCFrictionFreeSearchResultProductImage = styled.img`
  max-height: 25px;
  max-width: 25px;
`;
export const SCFrictionFreeSearchResultProductSourceContainer = styled.div`
  align-items: center;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  height: 45px;
  justify-content: center;
  margin-right: 15px;
  padding: 5px;
  text-align: center;
  width: 45px;
`;

export const SCFrictionFreeSearchResultProductSource = styled.img`
  max-height: 35px;
  max-width: 35px;
`;
