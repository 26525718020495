const config = {
  powerBi: {
    reportId: "22e42360-4a35-49de-b851-f5618599484e",
    embedUrl:
      "https://app.powerbi.com/reportEmbed?reportId=22e42360-4a35-49de-b851-f5618599484e&groupId=8881a159-7635-40e8-89d5-f8aeb92718ae&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLVdFU1QyLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d",
    accessToken:
      "H4sIAAAAAAAEAB2Ux66DVgBE_-VtiQQGAyZSFoBN59Kbd_TeLp0o_56X7Gc2Z3Tm7x8zvroxzn7-_Pkm_oVYfHZKwJE6mpkULtLv1GgR4sDXqV55TU8MB4uda--oh94jyUbhsjKys1nLhvTwsWCfbN_RRv2LWny12EK0lHXz6LWjFaDa3vvp5IP8zfmCQquCPPpyrqCglnhUP9qpV7EUQ5K6VNCP5tfnOn7NMk221ozlvK6kIir64VLJJ8YnvNDT39rD2YdwFMIBnsSQgPQtgGUQ7v1mY4Y-eAWlAXUPgqcIn-mrxMWYKzTbY5bR7QaG5dtSHs7jqeYtZgb-EUqk8sL7JfAqWt5MWtU0LMjLkuU7YCbIOMKJE_HgsS0T4Kycf68Cdmvrl7dTm1JLiielxGjnfnjsKtXU0pIWLUFzHfmVugKNGgqgVyV1IdC6zSLDGEwb_3LQQzKVh3keftYATA2NMz9nuXPuMvLHKDKG7rM6FYOuzaEH0VPWhkhwsThPdJN4rhjf-ra-IJUbC7Ir3noP9yxC83wySeSx4dM1i9SLUtAhLCDeMPdMIOPBWqVcNAx7IMRLdQaxGuzNc_OUyArywaRcJqiWXeakLfa87WcXaZQ8tQec2xzyXbJkdi7WN_Ma7ZUBTm4LDpNkXx2quul71cs_J8s-PShvGjMOW16XI-L50RhHdKd4e4GDLbyKqFb4VErBZDqSiJsiNhddhEcvgkYMWvwtKIfPk8xtc8HyFIcyY5vLGZuSPMPXOXfzfV0jpbg92KkXQnAHqZ8hW120NCn0e-VpUp-eAA1A-iHC9axcCaYBkXkWltYuMlrVirN7rmsnDmk4RoSlLvqQyA-Cn7gDW_djxjNvhS6ykwttyKsqJvt4k6IG2Vj-6-ePHx5e0zqq-fWrjsAVCfrAoYNoHM4jB9JSTE2JGGHBIWusyQpsBTwpls0MnwVr1Gefp0dVLfdGMos3Q91WXK-nbKguEIQZW6D0CQ4XYNy3QQDP8kIT00gWDDNbcsYbtSlbEAlf2MCeB9Y7eEZfZwnNAR_tvDxFxnFBOTIQU0SES92OtvcX-NLggzJSqNLELtDKE08vmES3UxGzEFlUnpJjKCkq4fH3SX1bxmEF3RrtuEBUQ2jG1cLfdNNxIcpoZU2QLld1-0Ef8PS4wgo4qGZfBiXxvsR8g8VXJ0iHRTgvMYoJTLS92Ypo8iHErKvq6eqm6-E37MCrOcUZXelVKB55XMJkdDqd6guaI2P99T_ma6pyKPu_lJ_MLOFdxwoQJ1-XjSaRAKjj_5RTl0O8bjD_jXEJL9qiTOR9UYt1Mh9ZryebXKxLkdVtCwujnFd2um5ZwM-zzZcLqwc2SolNuiI5VA-RAfyrQ8a1C-I4YsEz9lfzA6jPwdR0cQzuYO5dSD2ZEIWh-_XAVTb7IGNuFK6zINGPEHqPZWFyQFOwbm46XAO9M3wpCZ1U6tRR9DMjfLUrvIoL-FwcUnAUMejS0U0hpdAUXtWDmRVr8zX3V3pDaEVJwqO_VzI7bxzJrjApvfB4vbXAQYEzAAdYieAXDN4OZdORJq41Rn_Qv1Jqgez587Ah4ilLoWxU6BkpeB0uHHnlUnDh71MWs7zavLMVe3G9AnfoZvgRE87Mf5ckigA8jeg_zP_8Cw1HPsUuBgAA.eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLVdFU1QyLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6ZmFsc2V9fQ==",
  },
};
export { config };
