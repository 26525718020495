import { PageTitle, theme } from "@mainstem/react-mainstem";
import { Divider } from "@mantine/core";
import { ExampleData, PlaceholderStats } from "components";

const PagePurchaseOrderList: React.FC = () => {
  return (
    <>
      <PageTitle image={theme.logos.mainstem.color} title="Purchase Orders" />
      <PlaceholderStats
        data={[
          {
            title: "Created",
            icon: "receipt",
            value: "132",
            diff: 13,
          },
          {
            title: "Needing Approval",
            icon: "user",
            value: "2",
            diff: -2,
          },
          {
            title: "Approved",
            icon: "coin",
            value: "56",
            diff: 45,
          },
        ]}
      />
      <Divider my="md" />
      <ExampleData />
    </>
  );
};
export default PagePurchaseOrderList;
