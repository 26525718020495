// React
import { useState } from "react";
// Routing
import { Outlet, useNavigate } from "react-router-dom";
// Mantine
import {
  AppShell,
  Aside,
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
  MediaQuery,
  ScrollArea,
} from "@mantine/core";
import { SpotlightProvider } from "@mantine/spotlight";
import type { SpotlightAction } from "@mantine/spotlight";
// Icons
import {
  IconHome,
  IconSearch,
  IconDashboard,
  IconFileText,
} from "@tabler/icons";
// Local - Components
import { MSFeed, MSNavLeft, MSFooter, MSHeader } from "./components";

const Layout: React.FC = () => {
  const navigate = useNavigate();

  const [opened, setOpened] = useState(false);

  const [colorScheme, setColorScheme] = useState<ColorScheme>("light");
  const toggleColorScheme = () => {
    setColorScheme(colorScheme === "dark" ? "light" : "dark");
  };

  const actions: SpotlightAction[] = [
    {
      title: "Home",
      description: "Get to home page",
      onTrigger: () => {
        navigate("/");
      },
      icon: <IconHome size={18} />,
    },
    {
      title: "Dashboard",
      description: "Get full information about current system status",
      onTrigger: () => {
        navigate("/dashboard/approval");
      },
      icon: <IconDashboard size={18} />,
    },
    {
      title: "Integrations",
      description: "Visit documentation to lean more about all features",
      onTrigger: () => {
        navigate("/integrations");
      },
      icon: <IconFileText size={18} />,
    },
    {
      title: "Support & Documentation",
      description: "Visit documentation to lean more about all features",
      onTrigger: () => {
        navigate("/support");
      },
      icon: <IconFileText size={18} />,
    },
  ];

  return (
    <>
      <ColorSchemeProvider
        colorScheme={colorScheme}
        toggleColorScheme={toggleColorScheme}
      >
        <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          theme={{
            colorScheme: colorScheme,
            fontFamily: "'Poppins', sans-serif",
            fontFamilyMonospace: "'Space Mono', monospace",
            headings: { fontFamily: "'Poppins', sans-serif" },
            // colors: {
            //   brand: ["#00be52", "#440099", '#EC7CC3', '#ED5DB8', '#F13EAF', '#F71FA7', '#FF00A1', '#E00890', '#C50E82','#AD1374'],
            // },
            // primaryColor: "brand",
          }}
        >
          <AppShell
            navbarOffsetBreakpoint="md"
            asideOffsetBreakpoint="md"
            navbar={<MSNavLeft opened={opened} setOpened={setOpened} />}
            aside={
              <MediaQuery smallerThan="md" styles={{ display: "none" }}>
                <Aside
                  px="md"
                  hiddenBreakpoint="md"
                  width={{ sm: 200, lg: 300 }}
                  style={{
                    backgroundImage:
                      "linear-gradient(238deg, rgb(59 18 141 / 3%) 23%, rgb(1 175 250 / 4%))",
                    zIndex: 1,
                  }}
                >
                  <ScrollArea>
                    <MSFeed />
                  </ScrollArea>
                </Aside>
              </MediaQuery>
            }
            footer={<MSFooter />}
            header={
              <MSHeader
                colorScheme={colorScheme}
                opened={opened}
                setOpened={setOpened}
                toggleColorScheme={() => {
                  toggleColorScheme();
                }}
              />
            }
          >
            <Outlet />
          </AppShell>
          <SpotlightProvider
            actions={actions}
            searchIcon={<IconSearch size={18} />}
            searchPlaceholder="Search..."
            shortcut="mod + M"
            nothingFoundMessage="Nothing found..."
            transitionDuration={300}
            transition="slide-down"
          />
        </MantineProvider>
      </ColorSchemeProvider>
    </>
  );
};

export default Layout;
