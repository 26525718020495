import { PageTitle, theme } from "@mainstem/react-mainstem";
import { Divider } from "@mantine/core";
import { ExampleData, PlaceholderStats } from "components";

const PagePayments: React.FC = () => {
  return (
    <>
      <PageTitle image={theme.logos.mainstem.color} title="Payments" />
      <PlaceholderStats
        data={[
          {
            title: "A/R",
            icon: "receipt",
            value: "1453.63",
            diff: 13,
          },
          {
            title: "A/P",
            icon: "user",
            value: "65432.12",
            diff: -2,
          },
          {
            title: "Pending",
            icon: "coin",
            value: "56",
            diff: 45,
          },
        ]}
      />
      <Divider my="md" />
      <ExampleData />
    </>
  );
};
export default PagePayments;
