import {
  createStyles,
  Paper,
  Title,
  TextInput,
  Button,
  Container,
  Group,
  Anchor,
  Center,
  Box,
  Divider,
} from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons";
import { Link } from "react-router-dom";
import imgBackground from "assets/img/background.jpg";

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: 26,
    fontWeight: 900,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  controls: {
    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column-reverse",
    },
  },

  control: {
    [theme.fn.smallerThan("xs")]: {
      width: "100%",
      textAlign: "center",
    },
  },
}));
const PageAuthPasswordResetRequest: React.FC = () => {
  const { classes } = useStyles();

  return (
    <Center
      style={{
        backgroundSize: "100%",
        backgroundImage: `url(${imgBackground})`,
        height: "100vh",
        width: "100%",
      }}
    >
      <Container size={460}>
        <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
          <Title className={classes.title} align="center">
            Forgot your password?
          </Title>
          <Divider
            label="Enter your email to get a reset link"
            labelPosition="center"
            my="lg"
          />
          <TextInput label="Your email" placeholder="me@company.com" required />
          <Group position="apart" mt="lg" className={classes.controls}>
            <Anchor
              color="dimmed"
              component={Link}
              size="sm"
              className={classes.control}
              to="/auth/login"
            >
              <Center inline>
                <IconArrowLeft size={12} stroke={1.5} />
                <Box ml={5}>Back to login page</Box>
              </Center>
            </Anchor>
            <Button
              className={classes.control}
              variant="gradient"
              gradient={{ from: "indigo", to: "cyan" }}
            >
              Reset password
            </Button>
          </Group>
        </Paper>
      </Container>
    </Center>
  );
};

export default PageAuthPasswordResetRequest;
