// React
import { useState } from "react";
// Mantine
import { Divider, Input, SimpleGrid, Text } from "@mantine/core";
// Local - Components
import { Header, IntegrationCard, Tags } from "./components";
// Local - Data
import { integrations } from "./data";
import { IconSearch } from "@tabler/icons";
import {
  Button,
  ButtonGroup,
  PageTitle,
  theme,
} from "@mainstem/react-mainstem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faCreditCard, faUserShield } from "@fortawesome/pro-light-svg-icons";

const PageIntegrationsHome: React.FC = () => {
  const [tagFilters, setTagFilters] = useState<string[]>([]);

  const addFilter = (tag: string) => {
    setTagFilters([...tagFilters, tag]);
  };

  const removeFilter = (tag: string) => {
    setTagFilters(tagFilters.filter((item) => item !== tag));
  };

  const filteredIntegrations = () => {
    var integrationsFiltered = integrations;
    if (tagFilters.length > 0) {
      integrationsFiltered = integrations.filter((integration) => {
        return integration.tags.some((tag) => tagFilters.includes(tag.label));
      });
    }
    return integrationsFiltered.sort((a, b) => a.name.localeCompare(b.name));
  };

  return (
    <>
      <PageTitle
        actions={
          <ButtonGroup>
          <Button color='primary'>
            <FontAwesomeIcon icon={faUserShield} />
            &nbsp;OAuth - Single Sign On
          </Button>
            <Button color='primary'>
              <FontAwesomeIcon icon={faCalendar} />
              &nbsp;Book A Demo
            </Button>
            <Button>
              <FontAwesomeIcon icon={faCreditCard} />
              &nbsp;Purchase A License
            </Button>
          </ButtonGroup>
        }
        image={theme.logos.mainstem.color}
        title="Integrations"
      />
      <Header />
      <Divider my="md" />
      <Text color="dimmed" mb="md">
        Filter : Integration Types
      </Text>
      <Tags
        onAddFilter={(tag) => {
          console.log("addFilter", tag);
          addFilter(tag);
        }}
        onRemoveFilter={(tag) => {
          console.log("removeFilter", tag);
          removeFilter(tag);
        }}
      />
      <Input icon={<IconSearch />} placeholder="Search" size="xl" my="md" />
      <SimpleGrid
        breakpoints={[{ maxWidth: 992, cols: 2, spacing: "sm" }]}
        cols={4}
        mt={"md"}
      >
        {filteredIntegrations().map((integration) => {
          return <IntegrationCard {...integration} key={integration.to} />;
        })}
      </SimpleGrid>
    </>
  );
};
export default PageIntegrationsHome;
