// Routing
import { useNavigate } from "react-router-dom";
// Mantine
import {
  Button,
  Center,
  Container,
  Divider,
  Group,
  Paper,
  Text,
} from "@mantine/core";
import imgBackground from "assets/img/background.jpg";

const PageAuthLogout: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <Center
        style={{
          backgroundSize: "100%",
          backgroundImage: `url(${imgBackground})`,
          height: "100vh",
          width: "100%",
        }}
      >
        <Container size={460}>
          <Paper radius="md" p="xl" withBorder>
            <Text size="lg" weight={500}>
              Thank you for using MainStem
            </Text>

            <Divider
              label="We hope to see you soon!"
              labelPosition="center"
              my="lg"
            />

            <Group position="apart" mt="xl">
              <Button
                fullWidth
                onClick={() => {
                  navigate("/auth/login", { replace: true });
                }}
                variant="gradient"
                gradient={{ from: "indigo", to: "cyan" }}
              >
                Log Back In
              </Button>
            </Group>
          </Paper>
        </Container>
      </Center>
    </>
  );
};

export default PageAuthLogout;
