import {
  Title,
  Text,
  Container,
  Button,
  Overlay,
  createStyles,
} from "@mantine/core";
import { Link } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
    paddingTop: 50,
    paddingBottom: 40,
    backgroundImage:
      "url(https://images.unsplash.com/photo-1522204523234-8729aa6e3d5f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80)",
    backgroundSize: "cover",
    backgroundPosition: "center",

    "@media (max-width: 520px)": {
      paddingTop: 80,
      paddingBottom: 50,
    },
  },

  inner: {
    position: "relative",
    zIndex: 1,
  },

  title: {
    fontWeight: 800,
    fontSize: 40,
    letterSpacing: -1,
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    color: theme.white,
    marginBottom: theme.spacing.xs,
    textAlign: "center",
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    "@media (max-width: 520px)": {
      fontSize: 28,
      textAlign: "left",
    },
  },

  highlight: {
    color: "#00b5e2",
  },

  description: {
    color: theme.colors.gray[0],
    textAlign: "center",

    "@media (max-width: 520px)": {
      fontSize: theme.fontSizes.md,
      textAlign: "left",
    },
  },

  controls: {
    marginTop: theme.spacing.xl * 1.5,
    display: "flex",
    justifyContent: "center",
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,

    "@media (max-width: 520px)": {
      flexDirection: "column",
    },
  },

  control: {
    height: 42,
    fontSize: theme.fontSizes.md,

    "&:not(:first-of-type)": {
      marginLeft: theme.spacing.md,
    },

    "@media (max-width: 520px)": {
      "&:not(:first-of-type)": {
        marginTop: theme.spacing.md,
        marginLeft: 0,
      },
    },
  },

  secondaryControl: {},
}));
const Header: React.FC = () => {
  const { classes, cx } = useStyles();
  return (
    <>
      <div className={classes.wrapper}>
        <Overlay color="#000" opacity={0.65} zIndex={1} />

        <div className={classes.inner}>
          <Title className={classes.title}>
            Easily&nbsp;
            <Text component="span" inherit className={classes.highlight}>
              sell your products and services
            </Text>
          </Title>

          <Container size={640}>
            <Text size="lg" className={classes.description}>
              Use one of our integrations to easily sell your products on the
              MainStem Marketplace™ or offer your services to customers. Receive
              orders, ship, and collect payment all from one place.
            </Text>
          </Container>

          <div className={classes.controls}>
            <Button
              className={classes.control}
              component={Link}
              variant="white"
              size="lg"
              to="/opportunities"
            >
              Get started
            </Button>
            <Button
              className={cx(classes.control, classes.secondaryControl)}
              component={Link}
              size="lg"
              color="blue"
              variant="filled"
              to="/products/selling"
              ml={50}
            >
              Sell Your Products
            </Button>
            <Button
              className={cx(classes.control, classes.secondaryControl)}
              component={Link}
              size="lg"
              color="violet"
              variant="filled"
              to="/services/selling"
            >
              List Your Services
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export { Header };
