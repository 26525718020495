// MainStem
import { PageTitle, theme } from "@mainstem/react-mainstem";
import { Divider } from "@mantine/core";
import { ExampleData } from "components";

const PageServicesPurchasing: React.FC = () => {
  return (
    <>
      <PageTitle
        image={theme.logos.mainstem.color}
        title="Services You Purchase"
      />      
      <Divider my="md" />
      <ExampleData />
    </>
  );
};

export default PageServicesPurchasing;
