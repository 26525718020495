import { PageTitle, theme } from "@mainstem/react-mainstem";
import {
  createStyles,
  Container,
  Title,
  Text,
  Button,
  Grid,
  Stack,
  Group,
} from "@mantine/core";
import {
  IconMessage,
  IconMessageDots,
  IconPhoneCall,
  IconTicket,
} from "@tabler/icons";

const useStyles = createStyles((theme) => ({
  root: {
    backgroundColor: "#11284b",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage:
      "linear-gradient(250deg, rgba(130, 201, 30, 0) 0%, #062343 70%), url(https://images.unsplash.com/photo-1496115965489-21be7e6e59a0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80)",
    marginTop: -10,
    paddingTop: theme.spacing.xl * 3,
    paddingBottom: theme.spacing.xl * 3,
  },

  inner: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",

    [theme.fn.smallerThan("md")]: {
      flexDirection: "column",
    },
  },

  image: {
    [theme.fn.smallerThan("md")]: {
      display: "none",
    },
  },

  content: {
    paddingTop: theme.spacing.xl * 2,
    paddingBottom: theme.spacing.xl * 2,
    marginRight: theme.spacing.xl * 3,

    [theme.fn.smallerThan("md")]: {
      marginRight: 0,
    },
  },

  title: {
    color: theme.white,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 900,
    lineHeight: 1.05,
    maxWidth: 500,
    fontSize: 48,

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
      fontSize: 34,
      lineHeight: 1.15,
    },
  },

  description: {
    color: theme.white,
    opacity: 0.75,
    maxWidth: 500,

    [theme.fn.smallerThan("md")]: {
      maxWidth: "100%",
    },
  },

  control: {
    paddingLeft: 50,
    paddingRight: 50,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: 22,

    [theme.fn.smallerThan("md")]: {
      width: "100%",
    },
  },
}));

const PageSupport: React.FC = () => {
  const { classes } = useStyles();
  return (
    <>
      <PageTitle image={theme.logos.mainstem.color} title="Support" />
      <div className={classes.root}>
        <Container size="lg">
          <div className={classes.inner}>
            <Grid columns={3} style={{ width: "100%" }}>
              <Grid.Col span={2}>
                <div className={classes.content}>
                  <Title className={classes.title}>
                    Get the{" "}
                    <Text
                      component="span"
                      inherit
                      variant="gradient"
                      gradient={{ from: "#FAFAFA", to: "blue" }}
                    >
                      support
                    </Text>{" "}
                    you need, when you need it.
                  </Title>
                  <Text className={classes.description} mt={30}>
                    Build fully functional accessible web applications with ease
                    – Mantine includes more than 100 customizable components and
                    hooks to cover you in any situation
                  </Text>
                </div>
              </Grid.Col>
              <Grid.Col span={1}>
                <Button
                  variant="gradient"
                  gradient={{ from: "pink", to: "yellow" }}
                  size="xl"
                  className={classes.control}
                  fullWidth
                  mt={40}
                >
                  <IconTicket />
                  &nbsp;Submit a ticket
                </Button>
                <Button
                  variant="gradient"
                  gradient={{ from: "pink", to: "yellow" }}
                  size="xl"
                  className={classes.control}
                  fullWidth
                  mt={40}
                >
                  <IconMessage />
                  &nbsp;Chat with us
                </Button>
                <Button
                  variant="gradient"
                  gradient={{ from: "pink", to: "yellow" }}
                  size="xl"
                  className={classes.control}
                  fullWidth
                  mt={40}
                >
                  <Stack style={{ gap: 0 }}>
                    <Group m="0">
                      <IconMessageDots />
                      &nbsp;Email us
                    </Group>
                    <Text size="sm">support@mainstem.io</Text>
                  </Stack>
                </Button>
                <Button
                  variant="gradient"
                  gradient={{ from: "pink", to: "yellow" }}
                  size="xl"
                  className={classes.control}
                  fullWidth
                  mt={40}
                >
                  <Stack style={{ gap: 0 }}>
                    <Group m="0">
                      <IconPhoneCall />
                      &nbsp;Call us
                    </Group>
                    <Text size="sm">+1 844-623-8084</Text>
                  </Stack>
                </Button>
              </Grid.Col>
            </Grid>
          </div>
        </Container>
      </div>
    </>
  );
};

export default PageSupport;
