// Routing
import { Route, Routes } from "react-router-dom";
// Auth0
import { useAuth0 } from "@auth0/auth0-react";
// Layout
import Layout from "layout";
// Pages
import PageCustomerList from "pages/customer/list";
import PageHome from "pages/home";
import Page404 from "pages/404";
import PageCustomerDetails from "pages/customer/details";
import PageAuthAuthorize from "pages/auth/authorize";
import PageAuthLogin from "pages/auth/login";
import PageAuthRegister from "pages/auth/register";
import PageAuthPasswordResetProcess from "pages/auth/password-reset/process";
import PageAuthPasswordResetRequest from "pages/auth/password-reset/request";
import PageAuthLogout from "pages/auth/logout";
import PageAPI from "pages/api";
import PageDepartmentsIndex from "pages/departments/index";
import PageIntegrationsHome from "pages/integrations/home";
import PageLocationsList from "pages/locations/list";
import PageOpportunities from "pages/opportunities";
import PagePurchaseOrderList from "pages/purchase-order/list";
import PageSupport from "pages/support";
import PageSideKick from "pages/side-kick";
import PageFrictionFree from "pages/friction-free";
import PageBlog from "pages/blog";
import PageDirectPay from "pages/direct-pay";
import PageVendors from "pages/vendors";
import PageProductPurchasing from "pages/products/purchasing";
import PageProductSelling from "pages/products/selling";
import PageDashboardApproval from "pages/dashboard/approval";
import PageDashboardFinance from "pages/dashboard/finance";
import PageDashboardFulfillment from "pages/dashboard/fulfillment";
import PageDashboardInventory from "pages/dashboard/inventory";
import PageDashboardShipments from "pages/dashboard/shipments";
import PageAnalyticsMachineLearning from "pages/analytics/machine-learning";
import PageAnalyticsSupplyAudits from "pages/analytics/supply-audits";
import PageOrganization from "pages/organization";
import PageServicesSelling from "pages/services/selling";
import PageServicesPurchasing from "pages/services/purchasing";
import PageRequisitionsPurchasing from "pages/requisitions/purchasing";
import PageQuotesPurchasing from "pages/quotes/purchasing";
import PageQuotesSelling from "pages/quotes/selling";
import PageInvoicesPurchasing from "pages/invoices/purchasing";
import PageInvoicesSelling from "pages/invoices/selling";
import PageShipmentsPurchasing from "pages/shipments/purchasing";
import PageShipmentsSelling from "pages/shipments/selling";
import PageReceiving from "pages/receiving";
import PageSalesOrderList from "pages/sales-order/list";
import PagePayments from "pages/payments";
import PageReturnsAndRefunds from "pages/returns-and-refunds";
import PagePaymentMethodsCreditCards from "pages/payment-methods/credit-cards";
import PagePaymentMethodsACH from "pages/payment-methods/ach";
import PagePaymentMethodsTerms from "pages/payment-methods/terms";
import PagePaymentMethodsLendica from "pages/payment-methods/lendica";
import PageAccountSettings from "pages/account-settings";
import PageBillingOptions from "pages/billing-options";
import PageIntegrationSettings from "pages/integrations/settings";
import PageProfile from "pages/profile";
import PageUsersIndex from "pages/users/index";
import PagePermissions from "pages/permissions";
import PageCompliance from "pages/compliance";
import PagePurchaseOrderApprove from "pages/purchase-order/approve";
import PageBudgets from "pages/budgets";
import PageBills from "pages/bills";
import PageSecurityUsersIndex from "pages/security/users";
import PageSecurityPermissions from "pages/security/permissions";
import PageAIPurchasing from "pages/ai/purchasing";
import PageAISelling from "pages/ai/selling";

const AppRoutes: React.FC = () => {
  const { isAuthenticated } = useAuth0();
  return (
    <>
      <Routes>
        {isAuthenticated ? (
          <>
            <Route element={<Layout />}>
              <Route index element={<PageHome />} />
              <Route
                path="/account-settings"
                element={<PageAccountSettings />}
              />
              <Route path="/ai/purchasing" element={<PageAIPurchasing />} />
              <Route path="/ai/selling" element={<PageAISelling />} />
              <Route path="/ai/side-kick" element={<PageSideKick />} />
              <Route
                path="/analytics/machine-learning"
                element={<PageAnalyticsMachineLearning />}
              />
              <Route
                path="/analytics/supply-audits"
                element={<PageAnalyticsSupplyAudits />}
              />
              <Route path="/api" element={<PageAPI />} />
              <Route path="/billing-options" element={<PageBillingOptions />} />
              <Route path="/bills" element={<PageBills />} />
              <Route path="/blog" element={<PageBlog />} />
              <Route path="/budgets" element={<PageBudgets />} />
              <Route path="/compliance" element={<PageCompliance />} />
              <Route path="/customers" element={<PageCustomerList />} />
              <Route
                path="/customers/details/:id"
                element={<PageCustomerDetails />}
              />
              <Route
                path="/dashboard/approval"
                element={<PageDashboardApproval />}
              />
              <Route
                path="/dashboard/finance"
                element={<PageDashboardFinance />}
              />
              <Route
                path="/dashboard/fulfillment"
                element={<PageDashboardFulfillment />}
              />
              <Route
                path="/dashboard/inventory"
                element={<PageDashboardInventory />}
              />
              <Route
                path="/dashboard/shipments"
                element={<PageDashboardShipments />}
              />
              <Route path="/departments" element={<PageDepartmentsIndex />} />
              <Route path="/direct-pay" element={<PageDirectPay />} />
              <Route path="/friction-free" element={<PageFrictionFree />} />
              <Route path="/integrations" element={<PageIntegrationsHome />} />
              <Route
                path="/integrations/settings"
                element={<PageIntegrationSettings />}
              />
              <Route
                path="/invoices/purchasing"
                element={<PageInvoicesPurchasing />}
              />
              <Route
                path="/invoices/selling"
                element={<PageInvoicesSelling />}
              />
              <Route path="/locations" element={<PageLocationsList />} />
              <Route path="/opportunities" element={<PageOpportunities />} />
              <Route path="/organization" element={<PageOrganization />} />
              <Route path="/payments" element={<PagePayments />} />
              <Route
                path="/payment-methods/ach"
                element={<PagePaymentMethodsACH />}
              />
              <Route
                path="/payment-methods/credit-cards"
                element={<PagePaymentMethodsCreditCards />}
              />
              <Route
                path="/payment-methods/lendica"
                element={<PagePaymentMethodsLendica />}
              />
              <Route
                path="/payment-methods/terms"
                element={<PagePaymentMethodsTerms />}
              />
              <Route path="/permissions" element={<PagePermissions />} />
              <Route
                path="/products/purchasing"
                element={<PageProductPurchasing />}
              />
              <Route
                path="/products/selling"
                element={<PageProductSelling />}
              />
              <Route path="/profile" element={<PageProfile />} />
              <Route
                path="/purchase-orders"
                element={<PagePurchaseOrderList />}
              />
              <Route
                path="/purchase-orders/approve/:id"
                element={<PagePurchaseOrderApprove />}
              />
              <Route
                path="/quotes/purchasing"
                element={<PageQuotesPurchasing />}
              />
              <Route path="/quotes/selling" element={<PageQuotesSelling />} />
              <Route path="/receiving" element={<PageReceiving />} />
              <Route
                path="/requisitions/purchasing"
                element={<PageRequisitionsPurchasing />}
              />
              <Route
                path="/returns-and-refunds"
                element={<PageReturnsAndRefunds />}
              />
              <Route path="/sales-orders" element={<PageSalesOrderList />} />
              <Route
                path="/security/users"
                element={<PageSecurityUsersIndex />}
              />
              <Route
                path="/security/permissions"
                element={<PageSecurityPermissions />}
              />
              <Route
                path="/services/purchasing"
                element={<PageServicesPurchasing />}
              />
              <Route
                path="/services/selling"
                element={<PageServicesSelling />}
              />
              <Route
                path="/shipments/purchasing"
                element={<PageShipmentsPurchasing />}
              />
              <Route
                path="/shipments/selling"
                element={<PageShipmentsSelling />}
              />
              <Route path="/support" element={<PageSupport />} />
              <Route path="/users" element={<PageUsersIndex />} />
              <Route path="/vendors" element={<PageVendors />} />
              <Route path="*" element={<Page404 />} />
            </Route>
          </>
        ) : (
          <>
            <Route path="/authorizing" element={<PageAuthAuthorize />} />
            <Route path="/auth/login" element={<PageAuthLogin />} />
            <Route path="/auth/logout" element={<PageAuthLogout />} />
            <Route path="/auth/register" element={<PageAuthRegister />} />
            <Route
              path="/auth/password-reset/request"
              element={<PageAuthPasswordResetRequest />}
            />
            <Route
              path="/auth/password-reset/process/:id"
              element={<PageAuthPasswordResetProcess />}
            />
            <Route path="*" element={<PageAuthLogin />} />
          </>
        )}
      </Routes>
    </>
  );
};

export default AppRoutes;
